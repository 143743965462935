<section
	[editableInStoryblok]="_editable"
	class="section-spacing"
	data-test-class="storytelling-section"
>
	<div class="section-gap mx-auto max-w-screen-2xl">
		<sb-columns [colOne]="titleWithFigures" component="columnOne" />
		<div>
			@for (
				storytelling of storytellings;
				track storytelling;
				let index = $index,
			odd = $odd
			) {
				<vp-storytelling
					[editableInStoryblok]="storytelling._editable"
					[image]="vpImages[index]"
					[position]="odd ? 'left' : 'right'"
				>
					<header>
						<h2 class="heading-group heading-group-lg mb-4">
							{{ storytelling.title }}
						</h2>
						<p class="heading-group-overline" role="doc-subtitle">
							{{ storytelling.overline }}
						</p>
					</header>

					<div class="-mt-1.5 flex flex-col gap-1.5">
						@for (blok of storytelling.content; track $index) {
							@if (components) {
								<ndc-dynamic
									[ndcDynamicComponent]="
										components[blok['component']]
									"
									[ndcDynamicInputs]="blok"
								/>
							}
						}
					</div>
				</vp-storytelling>
			}
		</div>
	</div>
</section>
