import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { map, Observable, switchMap, take } from 'rxjs';

import { AccordionComponent } from '@valk-nx/components/ui-accordion/src/lib/accordion';
import { CarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import { CarouselItem } from '@valk-nx/components/ui-carousel/src/lib/carousel.interface';
import {
	ImageInterface,
	Sizes,
} from '@valk-nx/components/ui-image/src/lib/image.interface';
import { MiniCarouselComponent } from '@valk-nx/components/ui-mini-carousel/src/lib/mini-carousel';
import { PackageDetailsComponent } from '@valk-nx/components/ui-package-details/src/lib/package-details';
import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { RatingComponent } from '@valk-nx/components/ui-rating/src/lib/rating';
import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { ReadMoreHelper } from '@valk-nx/components/ui-read-more/src/lib/read-more.helper';
import { SheetModule } from '@valk-nx/components/ui-sheet/src/lib/sheet.module';
import { TabMenuComponent } from '@valk-nx/components/ui-tab-menu/src/lib/tab-menu';
import { TabMenuItemDirective } from '@valk-nx/components/ui-tab-menu/src/lib/tab-menu.directive';
import { HOTEL_SLUG, negativeHeader$ } from '@valk-nx/core/lib/core';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import { PackageData } from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'sb-package-detail',
	templateUrl: './package-detail.component.html',
	standalone: true,
	imports: [
		AccordionComponent,
		CarouselComponent,
		CommonModule,
		MarkdownModule,
		MiniCarouselComponent,
		PackageDetailsComponent,
		PricingComponent,
		RatingComponent,
		ReadMoreComponent,
		RouterModule,
		SheetModule,
		TabMenuComponent,
		TabMenuItemDirective,
		TranslateModule,
	],
})
export class PackageDetailComponent
	extends StoryblokRootDirective
	implements OnInit, AfterViewInit
{
	@Input({ required: true }) canonical!: string;
	@ViewChild('contentRef', { read: ElementRef }) contentRef: ElementRef;

	isSmallTablet$: Observable<boolean>;
	package$: Observable<PackageData>;

	sizes = Sizes;
	images: CarouselItem[] = [];
	hotelImages: ImageInterface[] = [];
	activePackageIndex = 0;
	parentUrl = '';
	disabledReadmore = true;

	constructor(
		private readonly contentService: ContentService,
		private readonly sbService: StoryblokService,
		private readonly metadataService: MetadataService,
		private readonly viewportService: ViewPortService,
		readonly cd: ChangeDetectorRef,
		@Inject(PLATFORM_ID) readonly platformId: string,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();
		this.isSmallTablet$ = this.viewportService.isSmallTablet$;
		this.package$ = this.sbService.translatedSlugs$.pipe(
			takeUntilDestroyed(),
			map((translatedSlugs) => {
				return (
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0]
				);
			}),
			switchMap((slug) => {
				this.parentUrl = slug.path.substring(
					0,
					slug.path.lastIndexOf('/') + 1,
				);

				return this.contentService.getPackage(
					slug.path.split('/').pop(),
					slug.lang.toLowerCase(),
				);
			}),
			map((response) => {
				const packageData = response.body.data;
				this.metadataService.updateMetaDataBatch(packageData);
				this.activePackageIndex = 0;

				this.images = packageData.images.map(
					(image: { src: string; alt: string }) => {
						return {
							image: {
								src: image.src,
								alt: image.alt,
							},
							heading: null,
							subHeading: null,
							link: null,
						};
					},
				);
				this.hotelImages = packageData.hotel?.images.map(
					(image: { src: string; alt: string }) => {
						return {
							src: image.src,
							altText: image.alt,
							width: 0,
							height: 0,
						};
					},
				);

				if (this.images.length > 0) {
					negativeHeader$.next(true);
				}

				return packageData;
			}),
		);
	}

	ngOnInit() {
		negativeHeader$.next(false);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.determineReadMore();
		});
	}

	@HostListener('window:resize')
	@Debounce(300)
	determineReadMore() {
		this.viewportService.isSmallTablet$
			.pipe(take(1))
			.subscribe((isSmallTablet) => {
				if (isPlatformBrowser(this.platformId)) {
					this.disabledReadmore = ReadMoreHelper.disabledReadmore(
						this.contentRef.nativeElement.offsetHeight,
						isSmallTablet,
					);
					this.cd.detectChanges();
				}
			});
	}
}
