import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	OnInit,
} from '@angular/core';

import { SwiperOptions } from 'swiper/types/swiper-options';

import { CarouselComponent as vpCarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import { CarouselItem } from '@valk-nx/components/ui-carousel/src/lib/carousel.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { CarouselItem as StoryblokCarouselItem } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-carousel',
	templateUrl: './carousel.html',
	standalone: true,
	imports: [vpCarouselComponent, StoryblokDirective],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarouselComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input() config: Partial<SwiperOptions> = {};
	@Input({ required: true }) items: StoryblokCarouselItem[] = [];

	carouselItems: CarouselItem[] = [];

	ngOnInit() {
		this.carouselItems = this.items
			.filter(
				(item) =>
					item.bynder?.length > 0 &&
					item.bynder[0].files?.transformBaseUrl?.url,
			)
			.map((item) => {
				const link =
					item.button?.length !== 1
						? null
						: {
								content: item.button[0].text,
								url: item.button[0].link.cached_url,
								target: item.button[0].link.target || '_self',
							};
				const sticker =
					item.sticker?.length !== 1
						? null
						: {
								src: item.sticker[0].files.transformBaseUrl.url,
								altText: '',
							};
				return {
					image: {
						src: item.bynder[0].files.transformBaseUrl.url,
						alt: item.imageAlt,
					},
					sticker,
					heading: item.heading,
					subHeading: item.subHeading,
					link,
				} as CarouselItem;
			});
	}
}
