import { CommonModule } from '@angular/common';
import { Component, effect, Input, input } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';

import { AccordionComponent as SbAccordionComponent } from '@valk-nx/storyblok/components/accordion/src/lib/accordion';
import { ActivateGiftcardComponent } from '@valk-nx/storyblok/components/activate-giftcard/src/lib/activate-giftcard';
import { AnchorComponent } from '@valk-nx/storyblok/components/anchor/src/lib/anchor';
import { BalanceGiftcardComponent } from '@valk-nx/storyblok/components/balance-giftcard/src/lib/balance-giftcard';
import { BookingWidgetComponent as SbBookingWidget } from '@valk-nx/storyblok/components/booking-widget/src/lib/booking-widget';
import { CardComponent } from '@valk-nx/storyblok/components/card/src/lib/card';
import { CtaCardComponent } from '@valk-nx/storyblok/components/cta-card/src/lib/cta-card';
import { DealCardComponent } from '@valk-nx/storyblok/components/deal-card/src/lib/deal-card';
import { FormComponent } from '@valk-nx/storyblok/components/form/src/lib/form';
import { HeadingGroupComponent } from '@valk-nx/storyblok/components/heading-group/src/lib/heading-group';
import { HeadingGroupSectionComponent } from '@valk-nx/storyblok/components/heading-group-section/src/lib/heading-group-section';
import { HotelByDealCardComponent } from '@valk-nx/storyblok/components/hotel-by-deal-card/src/lib/hotel-by-deal-card';
import { HotelByDealListComponent } from '@valk-nx/storyblok/components/hotel-by-deal-list/src/lib/hotel-by-deal-list';
import { ImageComponent as SbImageComponent } from '@valk-nx/storyblok/components/image/src/lib/image';
import { MarkdownComponent } from '@valk-nx/storyblok/components/markdown/src/lib/markdown';
import { MiniCarouselComponent as SbMiniCarouselComponent } from '@valk-nx/storyblok/components/mini-carousel/src/lib/mini-carousel';
import { OrderGiftcardComponent } from '@valk-nx/storyblok/components/order-giftcard/src/lib/order-giftcard';
import { OrderGiftcardBusinessComponent } from '@valk-nx/storyblok/components/order-giftcard-business/src/lib/order-giftcard-business';
import { PackageCardComponent } from '@valk-nx/storyblok/components/package-card/src/lib/package-card';
import { RichTextComponent } from '@valk-nx/storyblok/components/richtext/src/lib/rich-text';
import { RunningHeadComponent } from '@valk-nx/storyblok/components/running-head/src/lib/running-head';
import { SheetComponent } from '@valk-nx/storyblok/components/sheet/src/lib/sheet';
import { UspWrapperComponent } from '@valk-nx/storyblok/components/usp-wrapper/src/lib/usp-wrapper';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { ColumnAlignment } from '@valk-nx/storyblok-pipes/src/lib/pipes/column-alignment/column-alignment.pipe';
import {
	ColumnComponentAlignments,
	ColumnComponentTypes,
	ISbStoryBlok,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

const Components = {
	accordion: SbAccordionComponent,
	activateGiftcard: ActivateGiftcardComponent,
	balanceGiftcard: BalanceGiftcardComponent,
	bookingWidget: SbBookingWidget,
	button: AnchorComponent,
	card: CardComponent,
	ctaCard: CtaCardComponent,
	dealCard: DealCardComponent,
	form: FormComponent,
	headingGroup: HeadingGroupComponent,
	headingGroupSection: HeadingGroupSectionComponent,
	hotelByDealCard: HotelByDealCardComponent,
	hotelByDealList: HotelByDealListComponent,
	image: SbImageComponent,
	markdown: MarkdownComponent, // NOTE: Only being used in valk deals synced pages
	miniCarousel: SbMiniCarouselComponent,
	orderGiftcard: OrderGiftcardComponent,
	orderGiftcardBusiness: OrderGiftcardBusinessComponent,
	packageCard: PackageCardComponent,
	richText: RichTextComponent,
	runningHead: RunningHeadComponent,
	sheet: SheetComponent,
	uspwrapper: UspWrapperComponent,
};

@Component({
	selector: 'sb-columns',
	templateUrl: './columns.html',
	standalone: true,
	imports: [StoryblokDirective, CommonModule, DynamicModule, ColumnAlignment],
})
export class ColumnsComponent extends StoryblokRootDirective {
	@Input() title!: ISbStoryData[];
	@Input() colOne!: ISbStoryBlok[];
	@Input() colTwo!: ISbStoryBlok[];
	@Input() colThree!: ISbStoryBlok[];
	@Input() fullWidth = false;
	@Input() growColumns = false;
	@Input() reverse = false;
	@Input() declare component: ColumnComponentTypes;

	alignment = input<ColumnComponentAlignments>('left');
	components: typeof Components = Components;

	columnAlignment: 'items-start' | 'items-center' | 'items-end' =
		'items-start';

	constructor() {
		super();

		effect(() => {
			switch (this.alignment()) {
				case 'left':
					this.columnAlignment = 'items-start';
					break;
				case 'center':
				default:
					this.columnAlignment = 'items-center';
					break;
			}
		});
	}
}
