import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable, switchMap } from 'rxjs';

import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { PackageData } from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { Button } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-package-card',
	templateUrl: './package-card.html',
	standalone: true,
	imports: [
		CommonModule,
		LetDirective,
		PersuadeCardComponent,
		TranslateModule,
		StoryblokDirective,
	],
	host: { class: 'size-full' },
})
export class PackageCardComponent extends StoryblokRootDirective {
	@Input({ required: true }) package!: string;
	@Input({ required: true }) useVertical!: boolean;
	@Input({ required: true }) button!: Button[];

	package$: Observable<PackageData | undefined>;

	constructor(
		private readonly contentService: ContentService,
		private readonly sbService: StoryblokService,
	) {
		super();

		this.package$ = this.sbService.translatedSlugs$.pipe(
			map((translatedSlugs) => {
				return (
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0]
				);
			}),
			switchMap((slug) => {
				return this.contentService.getPackages(slug.lang.toLowerCase());
			}),
			map((response) => {
				return response.body?.data.find((x) => x.GUID === this.package);
			}),
		);
	}
}
