import { CurrencyPipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	InjectionToken,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
	BehaviorSubject,
	distinctUntilChanged,
	filter,
	interval,
	map,
	Observable,
	startWith,
	Subject,
	switchMap,
	takeUntil,
} from 'rxjs';

import { AlertService } from '@valk-nx/components/ui-alert/src/lib/services/alert.service';
import { Language } from '@valk-nx/core/lib/core';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import { PostalCodeFacade } from '@valk-nx/postal-code-store/lib/postal-code.facade';
import { GetAddressInterface } from '@valk-nx/postal-code-store/lib/postal-code.interface';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { alertErrorSettings } from '@valk-nx/storyblok-services/src/lib/globals';
import {
	GiftcardCustomer,
	GiftcardService,
	POLLING_TIME,
} from '@valk-nx/storyblok-services/src/lib/services/giftcard.service';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';

import { OrderStatus, OrderSteps, OrderSummary } from './order-giftcard.types';
import { form as formFields, orderFormGroup } from './order-giftcard-form';

export const SESSION_STORAGE = new InjectionToken<Storage>('session_storage');

@Component({
	selector: 'sb-order-giftcard',
	templateUrl: 'order-giftcard.html',
})
export class OrderGiftcardComponent
	extends StoryblokRootDirective
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild('orderGiftcardContainer') orderGiftcardContainer!: ElementRef;

	@Input() currency = 'EUR';
	@Input() productCode = '';
	@Input() giftcardName = 'Van der Valk Giftcard';
	@Input() maxAmountCards = 25;
	@Input() maxOrderCardAmount = 150;
	@Input() minOrderCardAmount = 5;
	@Input() isEGiftcard = false;

	// Only an input so it can be used with storybook easily
	@Input() orderSummary!: OrderSummary;
	@Input() orderStep = OrderSteps.Form;

	stopPolling$ = new Subject<void>();
	stopGUIDcheck$ = new Subject<void>();
	orderGUID = '';
	timeInterval$: Observable<number>;

	orderSteps = OrderSteps;

	options: FormlyFormOptions = {};
	formFields!: FormlyFieldConfig[];
	form = orderFormGroup;
	/* eslint-disable  @typescript-eslint/no-explicit-any */
	model: any = {};

	language: Language;
	orderStatus: OrderStatus = OrderStatus.success;

	isLoading = false;

	orderDataSessionName: 'eOrderGiftcardFormData' | 'orderGiftcardFormData' =
		'orderGiftcardFormData';
	orderSummarySessionName: 'eOrderSummary' | 'orderSummary' = 'orderSummary';

	constructor(
		private readonly translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private readonly giftcardService: GiftcardService,
		private readonly currencyPipe: CurrencyPipe,
		@Inject(SESSION_STORAGE)
		private sessionStorage: Storage,
		public readonly alertService: AlertService,
		@Inject(DOCUMENT)
		public document: Document,
		@Inject(POLLING_TIME)
		public readonly pollingTime: number,
		@Inject(PLATFORM_ID) private platformId: string,
		private readonly postalCodeFacade: PostalCodeFacade,
		private readonly tagManagerFacade: TagManagerFacade,
		private readonly ngZone: NgZone,
	) {
		super();
		this.language = (this.translate.currentLang ||
			this.translate.defaultLang) as Language;

		this.timeInterval$ = interval(pollingTime || 5000);
	}

	ngOnInit(): void {
		this.orderDataSessionName = this.isEGiftcard
			? 'eOrderGiftcardFormData'
			: 'orderGiftcardFormData';
		this.orderSummarySessionName = this.isEGiftcard
			? 'eOrderSummary'
			: 'orderSummary';
		this.formFields = formFields({
			formName: this.giftcardName,
			maxAmountCards: this.maxAmountCards,
			minOrderValueCards: this.minOrderCardAmount,
			maxOrderValueCards: this.maxOrderCardAmount,
			minOrderValueCardsCurrency: this.currencyPipe.transform(
				this.minOrderCardAmount,
				'EUR',
				'symbol-narrow',
				'1.2-2',
				this.language,
			),
			maxOrderValueCardsCurrency: this.currencyPipe.transform(
				this.maxOrderCardAmount,
				'EUR',
				'symbol-narrow',
				'1.2-2',
				this.language,
			),
			isEGiftcard: this.isEGiftcard,
		});

		this.postalCodeFacade
			.resolvePostalCode(
				this.form.get('customer')!.valueChanges.pipe(
					map((formValues) => {
						return {
							country: formValues.countryCode,
							houseNumber: formValues.houseNumber,
							houseNumberAddition: formValues.houseNumberAddition,
							postalCode: formValues.postalCode,
						};
					}),
					filter(
						(lookupValues) =>
							lookupValues.postalCode && lookupValues.houseNumber,
					),
					distinctUntilChanged(),
				),
				new BehaviorSubject({} as GetAddressInterface),
				(value) => this.form.get('customer')?.patchValue(value as any),
			)
			.subscribe();

		this.postalCodeFacade
			.resolvePostalCode(
				this.form.get('recipient')!.valueChanges.pipe(
					map((formValues) => {
						return {
							country: formValues.countryCode,
							houseNumber: formValues.houseNumber,
							houseNumberAddition: formValues.houseNumberAddition,
							postalCode: formValues.postalCode,
						};
					}),
					filter(
						(lookupValues) =>
							lookupValues.postalCode && lookupValues.houseNumber,
					),
					distinctUntilChanged(),
				),
				new BehaviorSubject({} as GetAddressInterface),
				(value) => this.form.get('recipient')?.patchValue(value as any),
			)
			.subscribe();

		this.orderGUID =
			this.activatedRoute.snapshot?.queryParams['orderguid'] || '';
		this.loadFormData();
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId) && this.orderGUID) {
			setTimeout(() => {
				this.toProcessingStep();
			}, 100);
		}
	}

	onSubmit(): void {
		if (isPlatformBrowser(this.platformId)) {
			if (this.form.valid) {
				this.isLoading = true;
				const numCards = parseInt(this.form.value.amountCards, 10);
				const orderValue =
					parseInt(this.form.value.orderValueCards, 10) * 100;
				this.giftcardService
					.getOrderSummary(
						numCards,
						orderValue,
						this.productCode,
						this.form.value.differentDeliveryAddress
							? this.form.value.recipient!.countryCode
							: this.form.value.customer!.countryCode,
						this.isEGiftcard,
					)
					.then((order) => {
						this.orderSummary = order;
						this.toSummaryStep();
					})
					.finally(() => {
						this.isLoading = false;
					});
			} else {
				this.scrollToError();
			}
		}
	}

	onCreateOrder() {
		this.isLoading = true;
		if (isPlatformBrowser(this.platformId)) {
			this.saveFormData();
			const numCards = parseInt(this.form.value.amountCards, 10);
			const orderValue =
				parseInt(this.form.value.orderValueCards, 10) * 100;
			this.giftcardService
				.createOrder(
					numCards,
					orderValue,
					this.productCode,
					this.form.value.customer as GiftcardCustomer,
					this.form.value.differentDeliveryAddress
						? (this.form.value.recipient as GiftcardCustomer)
						: undefined,
					this.isEGiftcard,
					this.form.value.message,
					document.location.href,
				)
				.then((orderResponse) => {
					this.isLoading = false;
					this.orderGUID = orderResponse.orderGUID;
					window.location.href = orderResponse.redirectURL;
				})
				.catch(() => {
					this.isLoading = false;
				});
		}
	}

	toFormStep(): void {
		this.orderStep = this.orderSteps.Form;
		this.scrollToTopOfOrderGiftcardContainer();
	}

	toSummaryStep(): void {
		this.orderStep = this.orderSteps.Summary;
		this.scrollToTopOfOrderGiftcardContainer();
	}

	toProcessingStep(): void {
		this.orderStep = this.orderSteps.Processing;
		this.startOrderStatusPolling();

		this.scrollToTopOfOrderGiftcardContainer();
	}

	toConfirmationStep(): void {
		if (isPlatformBrowser(this.platformId) && this.sessionStorage) {
			const orderData = JSON.parse(
				this.sessionStorage.getItem(this.orderDataSessionName)!,
			);
			const orderSummary = JSON.parse(
				this.sessionStorage.getItem(this.orderSummarySessionName)!,
			);

			this.tagManagerFacade.giftcardOrderB2C(
				orderData.amountCards,
				orderSummary.totalIncl / 100,
				orderData.customer.emailAddress,
				orderData.customer.phoneNumber,
			);
		}
		this.orderStep = this.orderSteps.Confirmation;
		this.clearFormData();
		this.scrollToTopOfOrderGiftcardContainer();
	}

	startOrderStatusPolling(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.ngZone.runOutsideAngular(() => {
				this.timeInterval$
					.pipe(
						takeUntil(this.stopPolling$),
						startWith(0),
						filter(() => !!this.orderGUID),
						// NOTE: we use the id to create a unique http GET call due to a bug in hydration, remove the retries when hydration cache is customizable (https://github.com/angular/angular/issues/50117)
						switchMap((id) => {
							return this.giftcardService.getOrderStatus(
								this.orderGUID,
								id,
							);
						}),
						filter((orderStatus) => !!orderStatus),
					)
					.subscribe({
						next: (orderStatus: string) => {
							this.ngZone.run(() => {
								this.handleOrderStatus(orderStatus);
							});
						},
						error: () => {
							this.ngZone.run(() => {
								this.handleOrderStatusError();
							});
						},
					});
			});
		}
	}

	handleOrderStatus(orderStatus: string): void {
		if (
			isPlatformBrowser(this.platformId) &&
			(orderStatus === OrderStatus.success ||
				orderStatus === OrderStatus.pending)
		) {
			this.orderStatus = OrderStatus[orderStatus];
			this.stopPolling$.next();
			this.toConfirmationStep();
		}
	}

	handleOrderStatusError(): void {
		this.alertService.error({
			...alertErrorSettings,
			content: this.translate.instant('global.service.error'),
		});
		this.toSummaryStep();
	}

	scrollToTopOfOrderGiftcardContainer(): void {
		if (isPlatformBrowser(this.platformId)) {
			const element = this.orderGiftcardContainer.nativeElement;
			const rect = element.getBoundingClientRect();
			if (rect.top < 0 || rect.top > 100) {
				window.scrollTo({
					top: element.offsetTop - 50,
					behavior: 'smooth',
				});
			}
		}
	}

	saveFormData(): void {
		if (isPlatformBrowser(this.platformId) && this.sessionStorage) {
			this.sessionStorage.setItem(
				this.orderDataSessionName,
				JSON.stringify(this.form.value),
			);

			this.sessionStorage.setItem(
				this.orderSummarySessionName,
				JSON.stringify(this.orderSummary),
			);
		}
	}

	loadFormData(): void {
		if (isPlatformBrowser(this.platformId) && this.sessionStorage) {
			const formData = JSON.parse(
				this.sessionStorage.getItem(this.orderDataSessionName)!,
			);
			const orderSummary = JSON.parse(
				this.sessionStorage.getItem(this.orderSummarySessionName)!,
			);

			if (formData && orderSummary) {
				this.model = formData;
				this.orderSummary = orderSummary;
				this.orderStep = this.orderSteps.Summary;
				setTimeout(() => {
					this.scrollToTopOfOrderGiftcardContainer();
				}, 250);
			}
		}
	}

	clearFormData(): void {
		if (isPlatformBrowser(this.platformId)) {
			const { history, location } = window;
			const urlPath = `${location.protocol}//${location.host}${location.pathname}`;
			history.replaceState({}, '', urlPath);

			this.sessionStorage.removeItem(this.orderDataSessionName);
			this.sessionStorage.removeItem(this.orderSummarySessionName);
		}
	}

	scrollToError() {
		setTimeout(() => {
			GeneralHelper.scrollToElementWithClass(
				'form-field-error',
				undefined,
				'input',
			);
		}, 100);
	}

	ngOnDestroy(): void {
		this.stopPolling$.next();
		this.stopGUIDcheck$.next();
	}
}
