import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DynamicModule } from 'ng-dynamic-component';

import { Components } from '@valk-nx/storyblok/components/reference/src/lib/reference.component';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-page',
	templateUrl: './page.component.html',
	standalone: true,
	imports: [CommonModule, DynamicModule],
})
export class PageComponent extends StoryblokRootDirective {
	components: typeof Components = Components;

	@Input({ required: true }) content!: unknown[];

	@Input({ required: true }) hideFromSitemap!: boolean;
	@Input({ required: true }) metaDescription!: string;
	@Input({ required: true }) metaTitle!: string;
	@Input({ required: true }) openGraphDescription!: string;
	@Input({ required: true }) openGraphImage!: { alt: string; file: string };
	@Input({ required: true }) openGraphTitle!: string;
	@Input({ required: true }) openGraphType!: string;
	@Input({ required: true }) robotsNoFollow!: boolean;
	@Input({ required: true }) robotsNoIndex!: boolean;
	@Input({ required: true }) canonical!: string;
}
