import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { DynamicModule, InputsType } from 'ng-dynamic-component';
import { combineLatest, from, map, Observable, switchMap } from 'rxjs';

import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { HOTEL_SLUG, Language } from '@valk-nx/core/lib/core';
import { removeTrailingSlash } from '@valk-nx/core/lib/ssr/ssr.helper';
import { CarouselComponent as SbCarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { SectionComponent } from '@valk-nx/storyblok/components/section/src/lib/section';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import { RoomData } from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { ISbStoryBlok } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

const Components = {
	carousel: SbCarouselComponent,
	section: SectionComponent,
};

@Component({
	selector: 'sb-room-overview',
	templateUrl: './room-overview.component.html',
	standalone: true,
	imports: [CommonModule, DynamicModule, PersuadeCardComponent],
})
export class RoomOverviewComponent extends StoryblokRootDirective {
	@Input({ required: true }) content!: ISbStoryBlok[];

	// Needed to "mute" errors in console
	@Input({ required: true }) canonical!: string;
	@Input({ required: true }) hideFromSitemap!: boolean;
	@Input({ required: true }) robotsNoIndex!: boolean;
	@Input({ required: true }) robotsNoFollow!: boolean;
	@Input({ required: true }) openGraphTitle!: string;
	@Input({ required: true }) openGraphDescription!: string;
	@Input({ required: true }) openGraphType!: string;
	@Input({ required: true }) openGraphImage!: { alt: string; file: string };
	@Input({ required: true }) metaDescription!: string;
	@Input({ required: true }) metaTitle!: string;

	components: typeof Components = Components;
	currentUrl = '';
	roomsData$: Observable<{ category: string; rooms: RoomData[] }[]>;

	get contentInputs(): (InputsType & { component: string })[] {
		return this.content.map((blok) => ({
			...blok,
			component: blok['component'] || '',
		}));
	}

	constructor(
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private router: Router,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();

		this.roomsData$ = this.sbService.translatedSlugs$.pipe(
			takeUntilDestroyed(),
			map((translatedSlugs) => {
				const { url } = this.router;
				this.currentUrl = removeTrailingSlash(url);
				const selectedSlug =
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0];
				const path =
					selectedSlug.lang.toLowerCase() !== 'nl'
						? selectedSlug.path.substring(3)
						: selectedSlug.path;
				return {
					path,
					language: selectedSlug.lang.toLowerCase() as Language,
				};
			}),
			switchMap(({ path, language }) =>
				combineLatest([
					from(
						this.sbService.getStoriesByQuery(
							{
								starts_with: path,
								excluding_slugs: path,
							},
							language,
						),
					),
					this.contentService.getRooms(language),
				]),
			),
			map(([storiesResponse, roomsResponse]) => {
				const storyblokRooms = storiesResponse.data.stories.map(
					(rooms) => rooms.full_slug.split('/').pop(),
				);
				const categories = roomsResponse.body.data.map(
					(room) => room.category,
				);
				const rooms = roomsResponse.body?.data.filter((room) =>
					storyblokRooms.includes(room.slug),
				);

				return [...new Set(categories)].map((category) => ({
					category,
					rooms: rooms.filter((room) => room.category === category),
				}));
			}),
		);
	}
}
