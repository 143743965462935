import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DynamicModule, InputsType } from 'ng-dynamic-component';
import { Observable } from 'rxjs';

import { ImageGridComponent } from '@valk-nx/compositions/ui-image-grid/src/lib/image-grid.component';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';
import { AnchorComponent } from '@valk-nx/storyblok/components/anchor/src/lib/anchor';
import { HeadingGroupComponent } from '@valk-nx/storyblok/components/heading-group/src/lib/heading-group';
import { ImageComponent } from '@valk-nx/storyblok/components/image/src/lib/image';
import { LinkListComponent as SbLinkListComponent } from '@valk-nx/storyblok/components/link-list/src/lib/link-list';
import { RichTextComponent } from '@valk-nx/storyblok/components/richtext/src/lib/rich-text';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import {
	CardImages,
	ISbStoryBlok,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

const Components = {
	button: AnchorComponent,
	headingGroup: HeadingGroupComponent,
	image: ImageComponent,
	linkList: SbLinkListComponent,
	richText: RichTextComponent,
};

@Component({
	selector: 'sb-card',
	templateUrl: './card.html',
	standalone: true,
	imports: [
		CommonModule,
		DynamicModule,
		StoryblokDirective,
		ImageGridComponent,
		ImageComponent,
	],
	host: { class: 'size-full' },
})
export class CardComponent extends StoryblokRootDirective {
	@Input() set images(images: CardImages[] | undefined) {
		this.bynderImages = images;
		this.flatImages = images
			?.filter(
				({ bynder }) =>
					bynder?.length > 0 &&
					bynder[0].files?.transformBaseUrl?.url,
			)
			.map(({ bynder, imageAlt }) => {
				return StoryblokHelper.mapBynderToPhotoInterface(
					bynder[0],
					imageAlt,
				);
			});
	}
	@Input({ required: true }) content!: ISbStoryBlok[];
	@Input() footer: ISbStoryBlok[] = [];
	@Input() isHorizontal = false;

	isResponsiveVertical$: Observable<boolean>;
	flatImages: PhotoInterface[] | undefined;
	bynderImages: CardImages[] | undefined;
	components: typeof Components = Components;

	get footerInputs(): (InputsType & { component: string })[] {
		return (
			this.footer?.map((blok) => ({
				...blok,
				component: blok['component'] || '',
			})) || []
		);
	}

	get contentInputs(): (InputsType & { component: string })[] {
		return this.content.map((blok) => ({
			...blok,
			component: blok['component'] || '',
		}));
	}

	constructor(private readonly viewport: ViewPortService) {
		super();
		this.isResponsiveVertical$ = this.viewport.isSmallTablet$;
	}
}
