<vp-link [link]="item().link" className="group" data-test-id="item">
	<div
		class="relative aspect-portrait w-full text-white clip-path-content md:text-lg"
	>
		<vp-image
			[image]="{
				src: item().image.src,
				altText: item().image.alt,
				width: 300,
				height: 450,
			}"
			className="size-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-105"
		/>
		<div
			class="absolute left-0 top-0 size-full bg-gradient-to-t from-[#0000008C] to-transparent"
		></div>
		<span
			class="elevation-3 absolute bottom-6 left-6 flex size-1 w-full items-center group-hover:underline"
		>
			{{ item().title }}
			<svg-icon
				class="flex size-4 transition-spacing group-hover:ml-0.5 md:size-5"
				src="assets/icons/icon-chevron-right.svg"
			/>
		</span>
	</div>
</vp-link>
