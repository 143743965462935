@if (story$ | async; as story) {
	<div [editableInStoryblok]="_editable">
		@if (story.content) {
			@for (contentBlok of story.content['content']; track contentBlok) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[contentBlok['component']]"
					[ndcDynamicInputs]="contentBlok"
				>
				</ndc-dynamic>
			}
		}
	</div>
}
