@if (roomData$ | async; as roomData) {
	<div data-test-id="room-detail">
		<div class="flex flex-col">
			<vp-carousel [items]="images" data-test-id="room-detail-carousel" />
			@if (roomData.isBookable) {
				<div class="z-10 -mt-14 px-3 md:px-8 2xl:px-10">
					<div class="mx-auto w-full max-w-screen-2xl">
						<div
							class="mx-auto flex w-full flex-col lg:w-10/12 2xl:w-8/12"
						>
							<vp-availability-widget
								[bookingtoolUrl]="
									this.bookingtool + this.hotelSlug
								"
								[hotelGUID]="roomData.hotel.GUID"
								[occupancyRestrictions]="
									roomData.occupancyRestrictions
								"
								[roomGUID]="roomData.GUID"
							/>
						</div>
					</div>
				</div>
			}
		</div>
		<section class="section-spacing bg-white">
			<div class="section-gap mx-auto max-w-screen-2xl">
				<div
					class="flex w-full flex-col gap-content-section md:mx-auto lg:w-10/12 lg:flex-row"
				>
					<div class="flex flex-col gap-content-section xl:w-2/3">
						<div>
							<header>
								<h1
									class="heading-group heading-group-lg"
									data-test-id="room-detail-title"
								>
									{{ roomData.title }}
								</h1>
								<p
									class="heading-group-overline"
									role="doc-subtitle"
								>
									{{ roomData.category }}
								</p>
							</header>
							<vp-room-facilities
								[babyBeds]="0"
								[facilities]="roomData.facilities"
								[maxNumberOfGuests]="0"
								[roomIndex]="0"
								[roomSurface]="roomData.surfaceArea"
								[showMoreInformationAccordion]="false"
								data-test-id="room-detail-facilities"
							/>
						</div>
						<div class="flex flex-row gap-3">
							<vp-tag color="accent">{{
								'global.checkin-time'
									| translate
										: {
												time: roomData.checkInTime,
										  }
							}}</vp-tag>
							<vp-tag color="accent">{{
								'global.checkout-time'
									| translate
										: {
												time: roomData.checkOutTime,
										  }
							}}</vp-tag>
						</div>
						<vp-read-more [isDisabled]="disabledReadmore">
							<markdown
								#contentRef
								[data]="roomData.description"
								class="markdown"
								data-test-id="room-detail-description"
							/>
						</vp-read-more>
						<div
							class="list-checkmarks list-grid flex w-full flex-col gap-4 md:gap-8"
						>
							<h2 class="running-head mb-0">
								{{ 'global.room' | translate }}
								{{ 'global.facilities' | translate }}
							</h2>
							<ng-container
								[ngTemplateOutlet]="list"
								[ngTemplateOutletContext]="{
									title: 'global.around-accommodation-type',
									listItems: roomFacilities || [],
									grid: true,
									name: 'facilities',
								}"
							/>
						</div>
					</div>
					<div class="flex flex-col gap-4 lg:w-1/3">
						@if (roomData.isBookable) {
							<vp-persuade-card
								[ctaText]="'global.book-directly' | translate"
								[ctaUrl]="
									this.bookingtool +
									this.hotelSlug +
									'?roomGUID=' +
									roomData.GUID
								"
								[ctaVariant]="'btn-outline'"
								[currency]="roomData.price.currency"
								[price]="roomData.price.amount"
								[reversedCtaColumn]="true"
								[title]="
									'global.book-accommodation-type'
										| translate
											: {
													type:
														([
															roomData.accommodationType,
														] | accommodationType),
											  }
								"
								[useVerticalCard]="true"
								data-test-id="cta-card"
								headingType="h2"
							>
								<ng-container content>
									<div class="list-checkmarks">
										<ul>
											<li data-test-id="cta-card-checkin">
												{{
													'global.checkin-time'
														| translate
															: {
																	time: roomData.checkInTime,
															  }
												}}
											</li>
											<li
												data-test-id="cta-card-checkout"
											>
												{{
													'global.checkout-time'
														| translate
															: {
																	time: roomData.checkOutTime,
															  }
												}}
											</li>
											<li>
												{{
													'global.best-price-guarantee'
														| translate
												}}
											</li>
										</ul>
									</div>
								</ng-container>
							</vp-persuade-card>
						}
						@if (roomData.hotel?.rating) {
							<vp-rating
								[isHorizontal]="isSmallTablet$ | async"
								[numberOfRatings]="
									roomData.hotel.rating.numberOfRatings
								"
								[score]="roomData.hotel.rating.score"
							/>
						}
					</div>
				</div>
				<div
					class="flex w-full flex-col gap-content-section md:mx-auto lg:w-10/12 lg:flex-row"
				>
					<div class="flex flex-col gap-8 lg:w-2/3">
						@if (roomData.hotel) {
							<h2
								class="running-head running-head-center"
								data-test-class="hotel-header"
							>
								{{
									'storyblok.pages.hotel-information'
										| translate
								}}
							</h2>
							@if (roomData.hotel.images?.length > 0) {
								<vp-mini-carousel
									[items]="hotelImages"
									aspectRatio="21:9"
								/>
							}
							<vp-tab-menu data-test-id="room-hotel-info">
								<vp-tab-menu-item
									[content]="facilitiesRef"
									[label]="'global.facilities' | translate"
									name="facilities"
								/>
								<vp-tab-menu-item
									[content]="goodToKnowsRef"
									[label]="'global.good-to-know' | translate"
									name="good-to-knows"
								/>
							</vp-tab-menu>
							<ng-template #facilitiesRef>
								<ng-container
									[ngTemplateOutlet]="list"
									[ngTemplateOutletContext]="{
										listItems:
											roomData.hotel?.facilities || [],
										grid: true,
										name: 'hotel-facilities',
									}"
								/>
							</ng-template>
							<ng-template #goodToKnowsRef>
								<ng-container
									[ngTemplateOutlet]="list"
									[ngTemplateOutletContext]="{
										listItems:
											roomData.hotel?.goodToKnows || [],
										grid: false,
										name: 'good-to-knows',
									}"
								/>
							</ng-template>
						}
					</div>
				</div>
			</div>
		</section>
	</div>
	<vp-sheet
		[isLoading]="false"
		[triggerId]="'facilities-trigger-sheet'"
		sheetWidth="third"
	>
		<vp-sheet-header>
			<span class="h2 block">
				{{ 'global.room' | translate }}
				{{ 'global.facilities' | translate }}
			</span>
		</vp-sheet-header>
		<vp-sheet-content id="sheet-content" tabindex="0">
			<div class="list-checkmarks">
				<ul>
					@for (item of roomFacilities || []; track $index) {
						<li>{{ item }}</li>
					}
				</ul>
			</div>
		</vp-sheet-content>
	</vp-sheet>
	<vp-sheet
		[isLoading]="false"
		[triggerId]="'hotel-facilities-trigger-sheet'"
		sheetWidth="third"
	>
		<vp-sheet-header>
			<span class="h2 block">
				{{ 'global.hotel' | translate }}
				{{ 'global.facilities' | translate }}
			</span>
		</vp-sheet-header>
		<vp-sheet-content id="sheet-content" tabindex="0">
			<div class="list-checkmarks">
				<ul>
					@for (
						item of roomData.hotel?.facilities || [];
						track $index
					) {
						<li>{{ item }}</li>
					}
				</ul>
			</div>
		</vp-sheet-content>
	</vp-sheet>
	<vp-sheet
		[isLoading]="false"
		[triggerId]="'good-to-knows-trigger-sheet'"
		sheetWidth="third"
	>
		<vp-sheet-header>
			<span class="h2 block">
				{{ 'global.good-to-know' | translate }}
			</span>
		</vp-sheet-header>
		<vp-sheet-content id="sheet-content" tabindex="0">
			<div class="list-checkmarks">
				<ul>
					@for (
						item of roomData.hotel?.goodToKnows || [];
						track $index
					) {
						<li>{{ item }}</li>
					}
				</ul>
			</div>
		</vp-sheet-content>
	</vp-sheet>
}

<ng-template
	#list
	let-accommodationType="accommodationType"
	let-grid="grid"
	let-listItems="listItems"
	let-name="name"
>
	<div [id]="name" class="list-checkmarks list-grid flex flex-col gap-4">
		<ul
			[ngClass]="{ 'sm:grid-cols-2': grid }"
			class="grid grid-cols-1 gap-4"
		>
			@for (
				item of listItems | slice: 0 : (grid ? 10 : 5);
				track $index
			) {
				<li>{{ item }}</li>
			}
		</ul>
		<button
			[id]="name + '-trigger-sheet'"
			class="text-link w-fit cursor-pointer"
			vp-sheet-trigger
		>
			{{ 'global.show-more' | translate }}
		</button>
	</div>
</ng-template>
