<div data-test-id="meeting-room-overview">
	@for (blok of contentInputs; track $index) {
		<div>
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
				/>
			}
		</div>
	}
	<section class="section-spacing bg-accent-25">
		<div
			class="section-gap mx-auto max-w-screen-2xl children:w-full children:md:mx-auto children:lg:w-10/12"
		>
			<h3 class="running-head" data-test-class="running-head">
				{{ 'global.meeting-rooms-label' | translate }}
			</h3>
			@if (meetingRooms$ | async; as meetingRooms) {
				@for (meetingRoom of meetingRooms; track $index) {
					<vp-persuade-card
						[cardUrl]="currentUrl + '/' + meetingRoom.slug"
						[ctaText]="
							'storyblok.pages.meeting-rooms.request' | translate
						"
						[ctaUrl]="currentUrl + '/' + meetingRoom.slug"
						[images]="meetingRoom.images"
						[roomSurface]="meetingRoom.surfaceArea"
						[showReadMore]="true"
						[title]="meetingRoom.name"
						data-test-class="meeting-room-card"
						imageVariant="mini-carousel"
					>
						<ng-container content>
							<vp-icon-grid
								[items]="meetingRoom.layouts"
								class="block pb-2"
								data-test-classs="layouts"
							/>
						</ng-container>
						<ng-container footerContent>
							<div class="text-left md:text-right">
								<p
									class="mb-0 text-lg font-semibold leading-snug text-dark-primary md:text-xl"
									data-test-class="max-persons"
								>
									{{
										'storyblok.pages.meeting-rooms.max-persons'
											| translate
												: {
														maxPersons:
															meetingRoom.maxPersons,
												  }
									}}
								</p>
								<p
									class="mb-0 text-sm leading-relaxed text-dark-tertiary md:text-base"
								>
									{{
										'storyblok.pages.meeting-rooms.capacity'
											| translate
									}}
								</p>
							</div>
						</ng-container>
					</vp-persuade-card>
				}
			}
		</div>
	</section>
</div>
