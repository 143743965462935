import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DynamicModule, InputsType } from 'ng-dynamic-component';

import { ColumnsComponent } from '@valk-nx/storyblok/components/columns/src/lib/columns';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	BackgroundColors,
	ISbStoryBlok,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

const Components = {
	columnOne: ColumnsComponent,
	columnThree: ColumnsComponent,
	columnTwo: ColumnsComponent,
	columnTwoThree: ColumnsComponent,
};

@Component({
	selector: 'sb-section',
	templateUrl: './section.html',
	standalone: true,
	imports: [CommonModule, DynamicModule, StoryblokDirective],
})
export class SectionComponent extends StoryblokRootDirective {
	@Input() sectionTitle = '';
	@Input() columns!: ISbStoryBlok[];
	@Input() sectionColor: BackgroundColors = 'white';

	components: typeof Components = Components;

	get columnsInput(): (InputsType & { component: string })[] {
		return this.columns.map((blok) => ({
			...blok,
			component: blok['component'] || '',
		}));
	}

	constructor() {
		super();
	}
}
