import { Component, HostBinding, Input, numberAttribute } from '@angular/core';

import { RibbonColor } from '@valk-nx/components/ui-ribbon/src/lib/ribbon';
import { DealCardModule } from '@valk-nx/compositions/ui-deal-card/src/lib/deal-card.component.module';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import {
	BynderImage,
	CardImages,
	Link,
	Text,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-deal-card',
	templateUrl: './deal-card.html',
	standalone: true,
	imports: [DealCardModule, StoryblokDirective],
})
export class DealCardComponent extends StoryblokRootDirective {
	@HostBinding('class') class = 'h-full';
	@Input() ribbonText = '';
	@Input() ribbonColor: RibbonColor = 'yellow';
	@Input() overline = '';
	@Input({ required: true }) title!: string;
	@Input() content = '';
	@Input({ transform: numberAttribute }) price = 0;
	@Input() size: 'base' | 'small' | 'large' = 'base';
	@Input() set tags(tags: Text[] | undefined) {
		this.flatTags = tags?.map((tag) => tag.text);
	}
	@Input() set images(images: CardImages[] | undefined) {
		this.flatImages = images
			?.filter(
				({ bynder }) =>
					bynder?.length > 0 &&
					bynder[0].files?.transformBaseUrl?.url,
			)
			.map(({ bynder, imageAlt }) => {
				return StoryblokHelper.mapBynderToPhotoInterface(
					bynder[0],
					imageAlt,
				);
			});
	}
	@Input() set sticker(sticker: BynderImage[] | undefined) {
		this.flatSticker = sticker?.[0]
			? {
					src: sticker[0].files.transformBaseUrl.url,
					alt: '',
				}
			: undefined;
	}
	@Input() set dealUrl(dealUrl: Link) {
		this.flatUrl = {
			href: dealUrl.cached_url,
			target: dealUrl.target || '_self',
		};
	}

	flatTags: string[] | undefined;
	flatImages: PhotoInterface[] | undefined;
	flatSticker: PhotoInterface | undefined;
	flatUrl!: { href: string; target?: string };
}
