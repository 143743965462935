<div
	[ngClass]="{
		'flex-row-reverse justify-between p-5': isHorizontal(),
		'flex-col justify-center p-7': !isHorizontal(),
	}"
	class="card flex items-center justify-center gap-2"
	data-test-class="rating"
>
	<div
		[ngClass]="{
			'mx-8': isHorizontal(),
		}"
		class="relative"
	>
		<ng-container [ngTemplateOutlet]="laurel" />
		<span
			[ngClass]="{
				'md:text-8xl': !isHorizontal(),
			}"
			class="text-4xl tracking-tighter text-accent-100"
			data-test-class="score"
		>
			{{ score() | number: '1.1-1' : language }}
		</span>
		<ng-container
			[ngTemplateOutlet]="laurel"
			[ngTemplateOutletContext]="{ invert: true }"
		/>
	</div>
	<div
		[ngClass]="{
			'text-center': !isHorizontal(),
		}"
		class="flex flex-col gap-2"
	>
		<span class="text-base md:text-lg" data-test-class="title">
			{{
				'components.rating.title'
					| translate
						: {
								score: score() | number: '1.1-1' : language,
								numberOfRatings: numberOfRatings(),
						  }
			}}
		</span>
		<span
			class="text-sm text-dark-quaternary md:text-base"
			data-test-class="subtitle"
		>
			{{
				'components.rating.subtitle'
					| translate
						: {
								score: score() | number: '1.1-1' : language,
								numberOfRatings: numberOfRatings(),
						  }
			}}
		</span>
	</div>
</div>

<ng-template #laurel let-invert="invert">
	<div
		[ngClass]="{
			'-right-8 -scale-x-100': invert,
			'-left-8': !invert,
			'md:-right-12': invert && !isHorizontal(),
			'md:-left-12': !invert && !isHorizontal(),
		}"
		class="absolute top-0"
	>
		<svg-icon
			[ngClass]="{
				'md:size-20': !isHorizontal(),
			}"
			class="inline-block size-12 text-accent-100 [mask-image:linear-gradient(to_bottom,rgba(0,0,0,0.2),rgba(255,0,0,0))]"
			src="assets/icons/illustration-laurel.svg"
		/>
	</div>
</ng-template>
