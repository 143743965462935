<section
	[editableInStoryblok]="_editable"
	[ngClass]="{
		'!bg-accent-25': sectionColor === 'accent',
	}"
	class="section-spacing bg-white"
>
	<div class="section-gap mx-auto max-w-screen-2xl">
		@for (blok of columnsInput; track $index) {
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
					class="hidden"
				/>
			}
		}
	</div>
</section>
