let H = !1;
const U = [],
  G = i => new Promise((e, t) => {
    if (typeof window > "u" || (window.storyblokRegisterEvent = r => {
      if (window.location === window.parent.location) {
        console.warn("You are not in Draft Mode or in the Visual Editor.");
        return;
      }
      H ? r() : U.push(r);
    }, document.getElementById("storyblok-javascript-bridge"))) return;
    const s = document.createElement("script");
    s.async = !0, s.src = i, s.id = "storyblok-javascript-bridge", s.onerror = r => t(r), s.onload = r => {
      U.forEach(n => n()), H = !0, e(r);
    }, document.getElementsByTagName("head")[0].appendChild(s);
  });
var W = Object.defineProperty,
  Q = (i, e, t) => e in i ? W(i, e, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: t
  }) : i[e] = t,
  d = (i, e, t) => Q(i, typeof e != "symbol" ? e + "" : e, t);
function z(i) {
  return !(i !== i || i === 1 / 0 || i === -1 / 0);
}
function Z(i, e, t) {
  if (!z(e)) throw new TypeError("Expected `limit` to be a finite number");
  if (!z(t)) throw new TypeError("Expected `interval` to be a finite number");
  const s = [];
  let r = [],
    n = 0;
  const o = function () {
      n++;
      const a = setTimeout(function () {
        n--, s.length > 0 && o(), r = r.filter(function (u) {
          return u !== a;
        });
      }, t);
      r.indexOf(a) < 0 && r.push(a);
      const c = s.shift();
      c.resolve(i.apply(c.self, c.args));
    },
    l = function (...a) {
      const c = this;
      return new Promise(function (u, p) {
        s.push({
          resolve: u,
          reject: p,
          args: a,
          self: c
        }), n < e && o();
      });
    };
  return l.abort = function () {
    r.forEach(clearTimeout), r = [], s.forEach(function (a) {
      a.reject(function () {
        Error.call(this, "Throttled function aborted"), this.name = "AbortError";
      });
    }), s.length = 0;
  }, l;
}
let O = class {
  constructor() {
    d(this, "isCDNUrl", (e = "") => e.indexOf("/cdn/") > -1), d(this, "getOptionsPage", (e, t = 25, s = 1) => ({
      ...e,
      per_page: t,
      page: s
    })), d(this, "delay", e => new Promise(t => setTimeout(t, e))), d(this, "arrayFrom", (e = 0, t) => [...Array(e)].map(t)), d(this, "range", (e = 0, t = e) => {
      const s = Math.abs(t - e) || 0,
        r = e < t ? 1 : -1;
      return this.arrayFrom(s, (n, o) => o * r + e);
    }), d(this, "asyncMap", async (e, t) => Promise.all(e.map(t))), d(this, "flatMap", (e = [], t) => e.map(t).reduce((s, r) => [...s, ...r], [])), d(this, "escapeHTML", function (e) {
      const t = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&#39;"
        },
        s = /[&<>"']/g,
        r = RegExp(s.source);
      return e && r.test(e) ? e.replace(s, n => t[n]) : e;
    });
  }
  /**
   * @method stringify
   * @param  {Object} params
   * @param  {String} prefix
   * @param  {Boolean} isArray
   * @return {String} Stringified object
   */
  stringify(e, t, s) {
    const r = [];
    for (const n in e) {
      if (!Object.prototype.hasOwnProperty.call(e, n)) continue;
      const o = e[n],
        l = s ? "" : encodeURIComponent(n);
      let a;
      typeof o == "object" ? a = this.stringify(o, t ? t + encodeURIComponent("[" + l + "]") : l, Array.isArray(o)) : a = (t ? t + encodeURIComponent("[" + l + "]") : l) + "=" + encodeURIComponent(o), r.push(a);
    }
    return r.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {String} regionCode region code, could be eu, us, cn, ap or ca
   * @return {String} The base URL of the region
   */
  getRegionURL(e) {
    const t = "api.storyblok.com",
      s = "api-us.storyblok.com",
      r = "app.storyblokchina.cn",
      n = "api-ap.storyblok.com",
      o = "api-ca.storyblok.com";
    switch (e) {
      case "us":
        return s;
      case "cn":
        return r;
      case "ap":
        return n;
      case "ca":
        return o;
      default:
        return t;
    }
  }
};
const ee = function (i, e) {
    const t = {};
    for (const s in i) {
      const r = i[s];
      e.indexOf(s) > -1 && r !== null && (t[s] = r);
    }
    return t;
  },
  te = i => i === "email",
  se = () => ({
    singleTag: "hr"
  }),
  re = () => ({
    tag: "blockquote"
  }),
  ie = () => ({
    tag: "ul"
  }),
  ne = i => ({
    tag: ["pre", {
      tag: "code",
      attrs: i.attrs
    }]
  }),
  oe = () => ({
    singleTag: "br"
  }),
  ae = i => ({
    tag: `h${i.attrs.level}`
  }),
  le = i => ({
    singleTag: [{
      tag: "img",
      attrs: ee(i.attrs, ["src", "alt", "title"])
    }]
  }),
  ce = () => ({
    tag: "li"
  }),
  he = () => ({
    tag: "ol"
  }),
  ue = () => ({
    tag: "p"
  }),
  de = i => ({
    tag: [{
      tag: "span",
      attrs: {
        "data-type": "emoji",
        "data-name": i.attrs.name,
        emoji: i.attrs.emoji
      }
    }]
  }),
  pe = () => ({
    tag: "b"
  }),
  ge = () => ({
    tag: "s"
  }),
  fe = () => ({
    tag: "u"
  }),
  me = () => ({
    tag: "strong"
  }),
  ye = () => ({
    tag: "code"
  }),
  be = () => ({
    tag: "i"
  }),
  ke = i => {
    if (!i.attrs) return {
      tag: ""
    };
    const e = new O().escapeHTML,
      t = {
        ...i.attrs
      },
      {
        linktype: s = "url"
      } = i.attrs;
    if (delete t.linktype, t.href && (t.href = e(i.attrs.href || "")), te(s) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), t.custom) {
      for (const r in t.custom) t[r] = t.custom[r];
      delete t.custom;
    }
    return {
      tag: [{
        tag: "a",
        attrs: t
      }]
    };
  },
  ve = i => ({
    tag: [{
      tag: "span",
      attrs: i.attrs
    }]
  }),
  $e = () => ({
    tag: "sub"
  }),
  Te = () => ({
    tag: "sup"
  }),
  Re = i => ({
    tag: [{
      tag: "span",
      attrs: i.attrs
    }]
  }),
  we = i => {
    var e;
    return (e = i.attrs) != null && e.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `background-color:${i.attrs.color};`
        }
      }]
    } : {
      tag: ""
    };
  },
  Se = i => {
    var e;
    return (e = i.attrs) != null && e.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `color:${i.attrs.color}`
        }
      }]
    } : {
      tag: ""
    };
  },
  _e = {
    nodes: {
      horizontal_rule: se,
      blockquote: re,
      bullet_list: ie,
      code_block: ne,
      hard_break: oe,
      heading: ae,
      image: le,
      list_item: ce,
      ordered_list: he,
      paragraph: ue,
      emoji: de
    },
    marks: {
      bold: pe,
      strike: ge,
      underline: fe,
      strong: me,
      code: ye,
      italic: be,
      link: ke,
      styled: ve,
      subscript: $e,
      superscript: Te,
      anchor: Re,
      highlight: we,
      textStyle: Se
    }
  },
  Ee = function (i) {
    const e = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      },
      t = /[&<>"']/g,
      s = RegExp(t.source);
    return i && s.test(i) ? i.replace(t, r => e[r]) : i;
  };
let B = !1;
class Ie {
  constructor(e) {
    d(this, "marks"), d(this, "nodes"), e || (e = _e), this.marks = e.marks || [], this.nodes = e.nodes || [];
  }
  addNode(e, t) {
    this.nodes[e] = t;
  }
  addMark(e, t) {
    this.marks[e] = t;
  }
  render(e, t = {
    optimizeImages: !1
  }, s = !0) {
    if (!B && s && (console.warn("Warning ⚠️: The RichTextResolver class is deprecated and will be removed in the next major release. Please use the `@storyblok/richtext` package instead. https://github.com/storyblok/richtext/"), B = !0), e && e.content && Array.isArray(e.content)) {
      let r = "";
      return e.content.forEach(n => {
        r += this.renderNode(n);
      }), t.optimizeImages ? this.optimizeImages(r, t.optimizeImages) : r;
    }
    return console.warn(`The render method must receive an Object with a "content" field.
			The "content" field must be an array of nodes as the type ISbRichtext.
			ISbRichtext:
				content?: ISbRichtext[]
				marks?: ISbRichtext[]
				attrs?: any
				text?: string
				type: string
				
				Example:
				{
					content: [
						{
							content: [
								{
									text: 'Hello World',
									type: 'text'
								}
							],
							type: 'paragraph'
						}
					],
					type: 'doc'
				}`), "";
  }
  optimizeImages(e, t) {
    let s = 0,
      r = 0,
      n = "",
      o = "";
    typeof t != "boolean" && (typeof t.width == "number" && t.width > 0 && (n += `width="${t.width}" `, s = t.width), typeof t.height == "number" && t.height > 0 && (n += `height="${t.height}" `, r = t.height), (t.loading === "lazy" || t.loading === "eager") && (n += `loading="${t.loading}" `), typeof t.class == "string" && t.class.length > 0 && (n += `class="${t.class}" `), t.filters && (typeof t.filters.blur == "number" && t.filters.blur >= 0 && t.filters.blur <= 100 && (o += `:blur(${t.filters.blur})`), typeof t.filters.brightness == "number" && t.filters.brightness >= -100 && t.filters.brightness <= 100 && (o += `:brightness(${t.filters.brightness})`), t.filters.fill && (t.filters.fill.match(/[0-9A-Fa-f]{6}/g) || t.filters.fill === "transparent") && (o += `:fill(${t.filters.fill})`), t.filters.format && ["webp", "png", "jpeg"].includes(t.filters.format) && (o += `:format(${t.filters.format})`), typeof t.filters.grayscale == "boolean" && t.filters.grayscale && (o += ":grayscale()"), typeof t.filters.quality == "number" && t.filters.quality >= 0 && t.filters.quality <= 100 && (o += `:quality(${t.filters.quality})`), t.filters.rotate && [90, 180, 270].includes(t.filters.rotate) && (o += `:rotate(${t.filters.rotate})`), o.length > 0 && (o = "/filters" + o))), n.length > 0 && (e = e.replace(/<img/g, `<img ${n.trim()}`));
    const l = s > 0 || r > 0 || o.length > 0 ? `${s}x${r}${o}` : "";
    return e = e.replace(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g, `a.storyblok.com/f/$1/$2.$3/m/${l}`), typeof t != "boolean" && (t.sizes || t.srcset) && (e = e.replace(/<img.*?src=["|'](.*?)["|']/g, a => {
      var c, u;
      const p = a.match(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g);
      if (p && p.length > 0) {
        const g = {
          srcset: (c = t.srcset) == null ? void 0 : c.map(y => {
            if (typeof y == "number") return `//${p}/m/${y}x0${o} ${y}w`;
            if (typeof y == "object" && y.length === 2) {
              let w = 0,
                j = 0;
              return typeof y[0] == "number" && (w = y[0]), typeof y[1] == "number" && (j = y[1]), `//${p}/m/${w}x${j}${o} ${w}w`;
            }
          }).join(", "),
          sizes: (u = t.sizes) == null ? void 0 : u.map(y => y).join(", ")
        };
        let k = "";
        return g.srcset && (k += `srcset="${g.srcset}" `), g.sizes && (k += `sizes="${g.sizes}" `), a.replace(/<img/g, `<img ${k.trim()}`);
      }
      return a;
    })), e;
  }
  renderNode(e) {
    const t = [];
    e.marks && e.marks.forEach(r => {
      const n = this.getMatchingMark(r);
      n && n.tag !== "" && t.push(this.renderOpeningTag(n.tag));
    });
    const s = this.getMatchingNode(e);
    return s && s.tag && t.push(this.renderOpeningTag(s.tag)), e.content ? e.content.forEach(r => {
      t.push(this.renderNode(r));
    }) : e.text ? t.push(Ee(e.text)) : s && s.singleTag ? t.push(this.renderTag(s.singleTag, " /")) : s && s.html ? t.push(s.html) : e.type === "emoji" && t.push(this.renderEmoji(e)), s && s.tag && t.push(this.renderClosingTag(s.tag)), e.marks && e.marks.slice(0).reverse().forEach(r => {
      const n = this.getMatchingMark(r);
      n && n.tag !== "" && t.push(this.renderClosingTag(n.tag));
    }), t.join("");
  }
  renderTag(e, t) {
    return e.constructor === String ? `<${e}${t}>` : e.map(s => {
      if (s.constructor === String) return `<${s}${t}>`;
      {
        let r = `<${s.tag}`;
        if (s.attrs) {
          for (const n in s.attrs) if (Object.prototype.hasOwnProperty.call(s.attrs, n)) {
            const o = s.attrs[n];
            o !== null && (r += ` ${n}="${o}"`);
          }
        }
        return `${r}${t}>`;
      }
    }).join("");
  }
  renderOpeningTag(e) {
    return this.renderTag(e, "");
  }
  renderClosingTag(e) {
    return e.constructor === String ? `</${e}>` : e.slice(0).reverse().map(t => t.constructor === String ? `</${t}>` : `</${t.tag}>`).join("");
  }
  getMatchingNode(e) {
    const t = this.nodes[e.type];
    if (typeof t == "function") return t(e);
  }
  getMatchingMark(e) {
    const t = this.marks[e.type];
    if (typeof t == "function") return t(e);
  }
  renderEmoji(e) {
    if (e.attrs.emoji) return e.attrs.emoji;
    const t = [{
      tag: "img",
      attrs: {
        src: e.attrs.fallbackImage,
        draggable: "false",
        loading: "lazy",
        align: "absmiddle"
      }
    }];
    return this.renderTag(t, " /");
  }
}
const L = Ie;
class je {
  constructor(e) {
    d(this, "baseURL"), d(this, "timeout"), d(this, "headers"), d(this, "responseInterceptor"), d(this, "fetch"), d(this, "ejectInterceptor"), d(this, "url"), d(this, "parameters"), d(this, "fetchOptions"), this.baseURL = e.baseURL, this.headers = e.headers || new Headers(), this.timeout = e != null && e.timeout ? e.timeout * 1e3 : 0, this.responseInterceptor = e.responseInterceptor, this.fetch = (...t) => e.fetch ? e.fetch(...t) : fetch(...t), this.ejectInterceptor = !1, this.url = "", this.parameters = {}, this.fetchOptions = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("get");
  }
  post(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("post");
  }
  put(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("put");
  }
  delete(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("delete");
  }
  async _responseHandler(e) {
    const t = [],
      s = {
        data: {},
        headers: {},
        status: 0,
        statusText: ""
      };
    e.status !== 204 && (await e.json().then(r => {
      s.data = r;
    }));
    for (const r of e.headers.entries()) t[r[0]] = r[1];
    return s.headers = {
      ...t
    }, s.status = e.status, s.statusText = e.statusText, s;
  }
  async _methodHandler(e) {
    let t = `${this.baseURL}${this.url}`,
      s = null;
    if (e === "get") {
      const a = new O();
      t = `${this.baseURL}${this.url}?${a.stringify(this.parameters)}`;
    } else s = JSON.stringify(this.parameters);
    const r = new URL(t),
      n = new AbortController(),
      {
        signal: o
      } = n;
    let l;
    this.timeout && (l = setTimeout(() => n.abort(), this.timeout));
    try {
      const a = await this.fetch(`${r}`, {
        method: e,
        headers: this.headers,
        body: s,
        signal: o,
        ...this.fetchOptions
      });
      this.timeout && clearTimeout(l);
      const c = await this._responseHandler(a);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(c)) : this._statusHandler(c);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  setFetchOptions(e = {}) {
    Object.keys(e).length > 0 && "method" in e && delete e.method, this.fetchOptions = {
      ...e
    };
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(e) {
    const t = /20[0-6]/g;
    return new Promise((s, r) => {
      if (t.test(`${e.status}`)) return s(e);
      const n = {
        message: e.statusText,
        status: e.status,
        response: Array.isArray(e.data) ? e.data[0] : e.data.error || e.data.slug
      };
      r(n);
    });
  }
}
const xe = je,
  D = "SB-Agent",
  C = {
    defaultAgentName: "SB-JS-CLIENT",
    defaultAgentVersion: "SB-Agent-Version",
    packageVersion: "6.0.0"
  };
let x = {};
const E = {};
class Oe {
  /**
   *
   * @param config ISbConfig interface
   * @param endpoint string, optional
   */
  constructor(e, t) {
    d(this, "client"), d(this, "maxRetries"), d(this, "retriesDelay"), d(this, "throttle"), d(this, "accessToken"), d(this, "cache"), d(this, "helpers"), d(this, "resolveCounter"), d(this, "relations"), d(this, "links"), d(this, "richTextResolver"), d(this, "resolveNestedRelations"), d(this, "stringifiedStoriesCache");
    let s = e.endpoint || t;
    if (!s) {
      const o = new O().getRegionURL,
        l = e.https === !1 ? "http" : "https";
      e.oauthToken ? s = `${l}://${o(e.region)}/v1` : s = `${l}://${o(e.region)}/v2`;
    }
    const r = new Headers();
    r.set("Content-Type", "application/json"), r.set("Accept", "application/json"), e.headers && (e.headers.constructor.name === "Headers" ? e.headers.entries().toArray() : Object.entries(e.headers)).forEach(([o, l]) => {
      r.set(o, l);
    }), r.has(D) || (r.set(D, C.defaultAgentName), r.set(C.defaultAgentVersion, C.packageVersion));
    let n = 5;
    e.oauthToken && (r.set("Authorization", e.oauthToken), n = 3), e.rateLimit && (n = e.rateLimit), e.richTextSchema ? this.richTextResolver = new L(e.richTextSchema) : this.richTextResolver = new L(), e.componentResolver && this.setComponentResolver(e.componentResolver), this.maxRetries = e.maxRetries || 10, this.retriesDelay = 300, this.throttle = Z(this.throttledRequest, n, 1e3), this.accessToken = e.accessToken || "", this.relations = {}, this.links = {}, this.cache = e.cache || {
      clear: "manual"
    }, this.helpers = new O(), this.resolveCounter = 0, this.resolveNestedRelations = e.resolveNestedRelations || !0, this.stringifiedStoriesCache = {}, this.client = new xe({
      baseURL: s,
      timeout: e.timeout || 0,
      headers: r,
      responseInterceptor: e.responseInterceptor,
      fetch: e.fetch
    });
  }
  setComponentResolver(e) {
    this.richTextResolver.addNode("blok", t => {
      let s = "";
      return t.attrs.body && t.attrs.body.forEach(r => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  }
  parseParams(e) {
    return e.token || (e.token = this.getToken()), e.cv || (e.cv = E[e.token]), Array.isArray(e.resolve_relations) && (e.resolve_relations = e.resolve_relations.join(",")), typeof e.resolve_relations < "u" && (e.resolve_level = 2), e;
  }
  factoryParamOptions(e, t) {
    return this.helpers.isCDNUrl(e) ? this.parseParams(t) : t;
  }
  makeRequest(e, t, s, r, n) {
    const o = this.factoryParamOptions(e, this.helpers.getOptionsPage(t, s, r));
    return this.cacheResponse(e, o, void 0, n);
  }
  get(e, t, s) {
    t || (t = {});
    const r = `/${e}`,
      n = this.factoryParamOptions(r, t);
    return this.cacheResponse(r, n, void 0, s);
  }
  async getAll(e, t, s, r) {
    const n = (t == null ? void 0 : t.per_page) || 25,
      o = `/${e}`,
      l = o.split("/"),
      a = s || l[l.length - 1],
      c = 1,
      u = await this.makeRequest(o, t, n, c, r),
      p = u.total ? Math.ceil(u.total / n) : 1,
      g = await this.helpers.asyncMap(this.helpers.range(c, p), k => this.makeRequest(o, t, n, k + 1, r));
    return this.helpers.flatMap([u, ...g], k => Object.values(k.data[a]));
  }
  post(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("post", r, t, s));
  }
  put(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("put", r, t, s));
  }
  delete(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("delete", r, t, s));
  }
  getStories(e, t) {
    return this._addResolveLevel(e), this.get("cdn/stories", e, t);
  }
  getStory(e, t, s) {
    return this._addResolveLevel(t), this.get(`cdn/stories/${e}`, t, s);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _addResolveLevel(e) {
    typeof e.resolve_relations < "u" && (e.resolve_level = 2);
  }
  _cleanCopy(e) {
    return JSON.parse(JSON.stringify(e));
  }
  _insertLinks(e, t, s) {
    const r = e[t];
    r && r.fieldtype == "multilink" && r.linktype == "story" && typeof r.id == "string" && this.links[s][r.id] ? r.story = this._cleanCopy(this.links[s][r.id]) : r && r.linktype === "story" && typeof r.uuid == "string" && this.links[s][r.uuid] && (r.story = this._cleanCopy(this.links[s][r.uuid]));
  }
  /**
   *
   * @param resolveId A counter number as a string
   * @param uuid The uuid of the story
   * @returns string | object
   */
  getStoryReference(e, t) {
    return this.relations[e][t] ? (this.stringifiedStoriesCache[t] || (this.stringifiedStoriesCache[t] = JSON.stringify(this.relations[e][t])), JSON.parse(this.stringifiedStoriesCache[t])) : t;
  }
  _insertRelations(e, t, s, r) {
    s.indexOf(`${e.component}.${t}`) > -1 && (typeof e[t] == "string" ? e[t] = this.getStoryReference(r, e[t]) : Array.isArray(e[t]) && (e[t] = e[t].map(n => this.getStoryReference(r, n)).filter(Boolean)));
  }
  iterateTree(e, t, s) {
    const r = n => {
      if (n != null) {
        if (n.constructor === Array) for (let o = 0; o < n.length; o++) r(n[o]);else if (n.constructor === Object) {
          if (n._stopResolving) return;
          for (const o in n) (n.component && n._uid || n.type === "link") && (this._insertRelations(n, o, t, s), this._insertLinks(n, o, s)), r(n[o]);
        }
      }
    };
    r(e.content);
  }
  async resolveLinks(e, t, s) {
    let r = [];
    if (e.link_uuids) {
      const n = e.link_uuids.length,
        o = [],
        l = 50;
      for (let a = 0; a < n; a += l) {
        const c = Math.min(n, a + l);
        o.push(e.link_uuids.slice(a, c));
      }
      for (let a = 0; a < o.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: o[a].join(",")
      })).data.stories.forEach(c => {
        r.push(c);
      });
    } else r = e.links;
    r.forEach(n => {
      this.links[s][n.uuid] = {
        ...n,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(e, t, s) {
    let r = [];
    if (e.rel_uuids) {
      const n = e.rel_uuids.length,
        o = [],
        l = 50;
      for (let a = 0; a < n; a += l) {
        const c = Math.min(n, a + l);
        o.push(e.rel_uuids.slice(a, c));
      }
      for (let a = 0; a < o.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: o[a].join(","),
        excluding_fields: t.excluding_fields
      })).data.stories.forEach(c => {
        r.push(c);
      });
    } else r = e.rels;
    r && r.length > 0 && r.forEach(n => {
      this.relations[s][n.uuid] = {
        ...n,
        _stopResolving: !0
      };
    });
  }
  /**
   *
   * @param responseData
   * @param params
   * @param resolveId
   * @description Resolves the relations and links of the stories
   * @returns Promise<void>
   *
   */
  async resolveStories(e, t, s) {
    var r, n;
    let o = [];
    if (this.links[s] = {}, this.relations[s] = {}, typeof t.resolve_relations < "u" && t.resolve_relations.length > 0 && (typeof t.resolve_relations == "string" && (o = t.resolve_relations.split(",")), await this.resolveRelations(e, t, s)), t.resolve_links && ["1", "story", "url", "link"].indexOf(t.resolve_links) > -1 && ((r = e.links) != null && r.length || (n = e.link_uuids) != null && n.length) && (await this.resolveLinks(e, t, s)), this.resolveNestedRelations) for (const l in this.relations[s]) this.iterateTree(this.relations[s][l], o, s);
    e.story ? this.iterateTree(e.story, o, s) : e.stories.forEach(l => {
      this.iterateTree(l, o, s);
    }), this.stringifiedStoriesCache = {}, delete this.links[s], delete this.relations[s];
  }
  async cacheResponse(e, t, s, r) {
    const n = this.helpers.stringify({
        url: e,
        params: t
      }),
      o = this.cacheProvider();
    if (this.cache.clear === "auto" && t.version === "draft" && (await this.flushCache()), t.version === "published" && e != "/cdn/spaces/me") {
      const l = await o.get(n);
      if (l) return Promise.resolve(l);
    }
    return new Promise(async (l, a) => {
      var c;
      try {
        const u = await this.throttle("get", e, t, r);
        if (u.status !== 200) return a(u);
        let p = {
          data: u.data,
          headers: u.headers
        };
        if ((c = u.headers) != null && c["per-page"] && (p = Object.assign({}, p, {
          perPage: u.headers["per-page"] ? parseInt(u.headers["per-page"]) : 0,
          total: u.headers["per-page"] ? parseInt(u.headers.total) : 0
        })), p.data.story || p.data.stories) {
          const g = this.resolveCounter = ++this.resolveCounter % 1e3;
          await this.resolveStories(p.data, t, `${g}`);
        }
        return t.version === "published" && e != "/cdn/spaces/me" && (await o.set(n, p)), p.data.cv && t.token && E[t.token] != p.data.cv && (await this.flushCache(), E[t.token] = p.data.cv), l(p);
      } catch (u) {
        if (u.response && u.status === 429 && (s = typeof s > "u" ? 0 : s + 1, s < this.maxRetries)) return console.log(`Hit rate limit. Retrying in ${this.retriesDelay / 1e3} seconds.`), await this.helpers.delay(this.retriesDelay), this.cacheResponse(e, t, s).then(l).catch(a);
        a(u);
      }
    });
  }
  throttledRequest(e, t, s, r) {
    return this.client.setFetchOptions(r), this.client[e](t, s);
  }
  cacheVersions() {
    return E;
  }
  cacheVersion() {
    return E[this.accessToken];
  }
  setCacheVersion(e) {
    this.accessToken && (E[this.accessToken] = e);
  }
  clearCacheVersion() {
    this.accessToken && (E[this.accessToken] = 0);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(e) {
            return Promise.resolve(x[e]);
          },
          getAll() {
            return Promise.resolve(x);
          },
          set(e, t) {
            return x[e] = t, Promise.resolve(void 0);
          },
          flush() {
            return x = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom) return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve();
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this.clearCacheVersion(), this;
  }
}
const ze = (i = {}) => {
    const {
      apiOptions: e
    } = i;
    if (!e.accessToken) {
      console.error("You need to provide an access token to interact with Storyblok API. Read https://www.storyblok.com/docs/api/content-delivery#topics/authentication");
      return;
    }
    return {
      storyblokApi: new Oe(e)
    };
  },
  Be = i => {
    if (typeof i != "object" || typeof i._editable > "u") return {};
    try {
      const e = JSON.parse(i._editable.replace(/^<!--#storyblok#/, "").replace(/-->$/, ""));
      return e ? {
        "data-blok-c": JSON.stringify(e),
        "data-blok-uid": e.id + "-" + e.uid
      } : {};
    } catch {
      return {};
    }
  };
function Le(i, e) {
  if (!e) return {
    src: i,
    attrs: {}
  };
  let t = 0,
    s = 0;
  const r = {},
    n = [];
  function o(a, c, u, p, g) {
    typeof a != "number" || a <= c || a >= u ? console.warn(`[StoryblokRichText] - ${p.charAt(0).toUpperCase() + p.slice(1)} value must be a number between ${c} and ${u} (inclusive)`) : g.push(`${p}(${a})`);
  }
  if (typeof e == "object") {
    if (typeof e.width == "number" && e.width > 0 ? (r.width = e.width, t = e.width) : console.warn("[StoryblokRichText] - Width value must be a number greater than 0"), e.height && typeof e.height == "number" && e.height > 0 ? (r.height = e.height, s = e.height) : console.warn("[StoryblokRichText] - Height value must be a number greater than 0"), e.loading && ["lazy", "eager"].includes(e.loading) && (r.loading = e.loading), e.class && (r.class = e.class), e.filters) {
      const {
          filters: a
        } = e || {},
        {
          blur: c,
          brightness: u,
          fill: p,
          format: g,
          grayscale: k,
          quality: y,
          rotate: w
        } = a || {};
      c && o(c, 0, 100, "blur", n), y && o(y, 0, 100, "quality", n), u && o(u, 0, 100, "brightness", n), p && n.push(`fill(${p})`), k && n.push("grayscale()"), w && [0, 90, 180, 270].includes(e.filters.rotate || 0) && n.push(`rotate(${w})`), g && ["webp", "png", "jpeg"].includes(g) && n.push(`format(${g})`);
    }
    e.srcset && (r.srcset = e.srcset.map(a => {
      if (typeof a == "number") return `${i}/m/${a}x0/${n.length > 0 ? `filters:${n.join(":")}` : ""} ${a}w`;
      if (Array.isArray(a) && a.length === 2) {
        const [c, u] = a;
        return `${i}/m/${c}x${u}/${n.length > 0 ? `filters:${n.join(":")}` : ""} ${c}w`;
      } else {
        console.warn("[StoryblokRichText] - srcset entry must be a number or a tuple of two numbers");
        return;
      }
    }).join(", ")), e.sizes && (r.sizes = e.sizes.join(", "));
  }
  let l = `${i}/m/`;
  return t > 0 && s > 0 && (l = `${l}${t}x${s}/`), n.length > 0 && (l = `${l}filters:${n.join(":")}`), {
    src: l,
    attrs: r
  };
}
var v = /* @__PURE__ */(i => (i.DOCUMENT = "doc", i.HEADING = "heading", i.PARAGRAPH = "paragraph", i.QUOTE = "blockquote", i.OL_LIST = "ordered_list", i.UL_LIST = "bullet_list", i.LIST_ITEM = "list_item", i.CODE_BLOCK = "code_block", i.HR = "horizontal_rule", i.BR = "hard_break", i.IMAGE = "image", i.EMOJI = "emoji", i.COMPONENT = "blok", i))(v || {}),
  $ = /* @__PURE__ */(i => (i.BOLD = "bold", i.STRONG = "strong", i.STRIKE = "strike", i.UNDERLINE = "underline", i.ITALIC = "italic", i.CODE = "code", i.LINK = "link", i.ANCHOR = "anchor", i.STYLED = "styled", i.SUPERSCRIPT = "superscript", i.SUBSCRIPT = "subscript", i.TEXT_STYLE = "textStyle", i.HIGHLIGHT = "highlight", i))($ || {}),
  V = /* @__PURE__ */(i => (i.TEXT = "text", i))(V || {}),
  I = /* @__PURE__ */(i => (i.URL = "url", i.STORY = "story", i.ASSET = "asset", i.EMAIL = "email", i))(I || {});
const Ae = ["area", "base", "br", "col", "embed", "hr", "img", "input", "link", "meta", "param", "source", "track", "wbr"],
  Ce = (i = {}) => Object.keys(i).map(e => `${e}="${i[e]}"`).join(" "),
  Pe = (i = {}) => Object.keys(i).map(e => `${e}: ${i[e]}`).join("; ");
function Ne(i) {
  return i.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}
const q = i => Object.fromEntries(Object.entries(i).filter(([e, t]) => t !== void 0));
function Me(i, e = {}, t) {
  const s = Ce(e),
    r = s ? `${i} ${s}` : i;
  return Ae.includes(i) ? `<${r}>` : `<${r}>${Array.isArray(t) ? t.join("") : t || ""}</${i}>`;
}
function De(i = {}) {
  let e = 0;
  const {
      renderFn: t = Me,
      textFn: s = Ne,
      resolvers: r = {},
      optimizeImages: n = !1,
      keyedResolvers: o = !1
    } = i,
    l = h => f => {
      const m = f.attrs || {};
      return o && (m.key = `${h}-${e}`), t(h, m, f.children || null);
    },
    a = h => {
      const {
        src: f,
        alt: m,
        title: b,
        srcset: R,
        sizes: T
      } = h.attrs || {};
      let S = f,
        _ = {};
      if (n) {
        const {
          src: Y,
          attrs: X
        } = Le(f, n);
        S = Y, _ = X;
      }
      o && (_ = {
        ..._,
        key: `img-${e}`
      });
      const K = {
        src: S,
        alt: m,
        title: b,
        srcset: R,
        sizes: T,
        ..._
      };
      return t("img", q(K));
    },
    c = h => {
      const {
          level: f,
          ...m
        } = h.attrs || {},
        b = {
          ...m
        };
      return o && (b.key = `h${f}-${e}`), t(`h${f}`, b, h.children);
    },
    u = h => {
      var f, m, b, R;
      const T = t("img", {
          src: (f = h.attrs) == null ? void 0 : f.fallbackImage,
          alt: (m = h.attrs) == null ? void 0 : m.alt,
          style: "width: 1.25em; height: 1.25em; vertical-align: text-top",
          draggable: "false",
          loading: "lazy"
        }),
        S = {
          "data-type": "emoji",
          "data-name": (b = h.attrs) == null ? void 0 : b.name,
          "data-emoji": (R = h.attrs) == null ? void 0 : R.emoji
        };
      return o && (S.key = `emoji-${e}`), t("span", S, T);
    },
    p = h => t("pre", {
      ...h.attrs,
      key: `code-${e}`
    }, t("code", {
      key: `code-${e}`
    }, h.children || "")),
    g = (h, f = !1) => ({
      text: m,
      attrs: b
    }) => {
      const {
          class: R,
          id: T,
          ...S
        } = b || {},
        _ = f ? {
          class: R,
          id: T,
          style: Pe(S) || void 0
        } : b || {};
      return o && (_.key = `${h}-${e}`), t(h, q(_), m);
    },
    k = h => A(h),
    y = h => {
      const {
        marks: f,
        ...m
      } = h;
      return "text" in h ? f ? f.reduce((b, R) => k({
        ...R,
        text: b
      }),
      // Fix: Ensure render function returns a string
      k({
        ...m,
        children: m.children
      })
      // Fix: Cast children to string
      ) : s(m.text) : "";
    },
    w = h => {
      const {
        linktype: f,
        href: m,
        anchor: b,
        ...R
      } = h.attrs || {};
      let T = "";
      switch (f) {
        case I.ASSET:
        case I.URL:
          T = m;
          break;
        case I.EMAIL:
          T = `mailto:${m}`;
          break;
        case I.STORY:
          T = m;
          break;
      }
      return b && (T = `${T}#${b}`), t("a", {
        ...R,
        href: T,
        key: `a-${e}`
      }, h.text);
    },
    j = h => {
      var f, m;
      return console.warn("[StoryblokRichtText] - BLOK resolver is not available for vanilla usage"), t("span", {
        blok: (f = h == null ? void 0 : h.attrs) == null ? void 0 : f.body[0],
        id: (m = h.attrs) == null ? void 0 : m.id,
        key: `component-${e}`,
        style: "display: none"
      });
    },
    F = new Map([[v.DOCUMENT, l("div")], [v.HEADING, c], [v.PARAGRAPH, l("p")], [v.UL_LIST, l("ul")], [v.OL_LIST, l("ol")], [v.LIST_ITEM, l("li")], [v.IMAGE, a], [v.EMOJI, u], [v.CODE_BLOCK, p], [v.HR, l("hr")], [v.BR, l("br")], [v.QUOTE, l("blockquote")], [v.COMPONENT, j], [V.TEXT, y], [$.LINK, w], [$.ANCHOR, w], [$.STYLED, g("span", !0)], [$.BOLD, g("strong")], [$.TEXT_STYLE, g("span", !0)], [$.ITALIC, g("em")], [$.UNDERLINE, g("u")], [$.STRIKE, g("s")], [$.CODE, g("code")], [$.SUPERSCRIPT, g("sup")], [$.SUBSCRIPT, g("sub")], [$.HIGHLIGHT, g("mark")], ...Object.entries(r).map(([h, f]) => [h, f])]);
  function M(h) {
    e += 1;
    const f = F.get(h.type);
    if (!f) return console.error("<Storyblok>", `No resolver found for node type ${h.type}`), "";
    if (h.type === "text") return f(h);
    const m = h.content ? h.content.map(A) : void 0;
    return f({
      ...h,
      children: m
      // Fix: Update the type of 'children' to Node[]
    });
  }
  function A(h) {
    return Array.isArray(h) ? h.map(M) : M(h);
  }
  return {
    render: A
  };
}
let P,
  N = "https://app.storyblok.com/f/storyblok-v2-latest.js";
const qe = (i, e, t = {}) => {
    var l;
    const r = !(typeof window > "u") && typeof window.storyblokRegisterEvent < "u",
      o = +new URL((l = window.location) == null ? void 0 : l.href).searchParams.get("_storyblok") === i;
    if (!(!r || !o)) {
      if (!i) {
        console.warn("Story ID is not defined. Please provide a valid ID.");
        return;
      }
      window.storyblokRegisterEvent(() => {
        new window.StoryblokBridge(t).on(["input", "published", "change"], c => {
          c.action === "input" && c.story.id === i ? e(c.story) : (c.action === "change" || c.action === "published") && c.storyId === i && window.location.reload();
        });
      });
    }
  },
  Ge = (i = {}) => {
    var p, g;
    const {
      bridge: e,
      accessToken: t,
      use: s = [],
      apiOptions: r = {},
      richText: n = {},
      bridgeUrl: o
    } = i;
    r.accessToken = r.accessToken || t;
    const l = {
      bridge: e,
      apiOptions: r
    };
    let a = {};
    s.forEach(k => {
      a = {
        ...a,
        ...k(l)
      };
    }), o && (N = o);
    const u = !(typeof window > "u") && ((g = (p = window.location) == null ? void 0 : p.search) == null ? void 0 : g.includes("_storyblok_tk"));
    return e !== !1 && u && G(N), P = new L(n.schema), n.resolver && J(P, n.resolver), a;
  },
  J = (i, e) => {
    i.addNode("blok", t => {
      let s = "";
      return t.attrs.body.forEach(r => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  },
  He = i => !i || !(i != null && i.content.some(e => e.content || e.type === "blok" || e.type === "horizontal_rule")),
  Ve = (i, e, t) => {
    let s = t || P;
    if (!s) {
      console.error("Please initialize the Storyblok SDK before calling the renderRichText function");
      return;
    }
    return He(i) ? "" : (e && (s = new L(e.schema), e.resolver && J(s, e.resolver)), s.render(i, {}, !1));
  },
  Je = () => G(N);
export { v as BlockTypes, $ as MarkTypes, L as RichTextResolver, _e as RichTextSchema, V as TextTypes, ze as apiPlugin, He as isRichTextEmpty, Je as loadStoryblokBridge, qe as registerStoryblokBridge, Ve as renderRichText, De as richTextResolver, Be as storyblokEditable, Ge as storyblokInit, qe as useStoryblokBridge };