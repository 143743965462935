<div data-test-id="meeting-room-detail" id="meeting-room-detail">
	@if (meetingRoom$ | async; as meetingRoom) {
		@if ((showForm$ | async) === false) {
			@if (meetingRoom.images.length > 0) {
				<vp-carousel [items]="images" data-test-id="carousel" />
			}
			<section class="section-spacing bg-white" id="meeting-room">
				<div class="section-gap mx-auto max-w-screen-2xl">
					<div
						class="flex w-full flex-col gap-content-section md:mx-auto lg:w-10/12 lg:flex-row"
					>
						<div class="flex flex-col gap-content-section lg:w-2/3">
							<div>
								<header data-test-class="intro-header">
									<h1
										class="heading-group heading-group-lg"
										data-test-class="title"
									>
										{{ meetingRoom.name }}
									</h1>
									<p
										class="heading-group-overline"
										data-test-class="overline"
										role="doc-subtitle"
									>
										{{
											'global.meeting-room-label'
												| translate
										}}
									</p>
								</header>
							</div>
							<vp-read-more [isDisabled]="disabledReadmore">
								<markdown
									#contentRef
									[data]="meetingRoom.description"
									class="markdown block"
									data-test-class="package-detail-description"
								/>
							</vp-read-more>
							@if (iconGridItems.length > 0) {
								<vp-icon-grid
									[items]="iconGridItems"
									data-test-id="layouts"
								/>
							}
						</div>
						<div class="flex flex-col gap-4 lg:w-1/3">
							<div
								class="card card-lg flex flex-col gap-4"
								data-test-id="mini-booking-widget"
							>
								<header>
									<div
										class="heading-group"
										data-test-id="title"
									>
										<h2 class="heading-group">
											{{
												'storyblok.page.meeting-room.mini-booking-widget-title'
													| translate
											}}
										</h2>
									</div>
								</header>
							</div>
							@if (meetingRoom.hotel?.rating) {
								<vp-rating
									[isHorizontal]="isSmallTablet$ | async"
									[numberOfRatings]="
										meetingRoom.hotel.rating.numberOfRatings
									"
									[score]="meetingRoom.hotel.rating.score"
								/>
							}
						</div>
					</div>
					<div
						class="flex w-full flex-col gap-content-section md:mx-auto lg:w-10/12 lg:flex-row"
					>
						<div class="flex flex-col gap-8 lg:w-2/3">
							@if (meetingRoom.hotel) {
								<h2
									class="running-head running-head-center"
									data-test-class="hotel-header"
								>
									{{
										'storyblok.pages.hotel-information'
											| translate
									}}
								</h2>
								@if (meetingRoom.hotel.images?.length > 0) {
									<vp-mini-carousel
										[items]="hotelImages"
										aspectRatio="21:9"
									/>
								}
							}
						</div>
					</div>
				</div>
			</section>
		} @else {
			@defer {
				<sb-meeting-room-detail-request-form
					[iconGridItems]="iconGridItems"
					[image]="
						meetingRoom.images.length > 0
							? meetingRoom.images[0]
							: null
					"
					[language]="language"
					[meetingroomName]="meetingRoom.name"
					(formClosed)="onFormClosed($event)"
				/>
			}
		}
	}
</div>
