<div
	class="grid grid-cols-2 border-l border-t border-ui-100 lg:grid-cols-3"
	data-test-class="icon-grid"
>
	@for (item of items; track $index) {
		<div
			class="flex items-center border-b border-r border-ui-100 px-2 py-1.5 text-sm md:text-base"
		>
			<svg-icon
				[ngClass]="{ 'opacity-25': !item.subtitle }"
				[src]="item.icon"
				class="mr-3 size-6 text-ui-150"
			/>
			<div
				[ngClass]="{ 'opacity-25': !item.subtitle }"
				class="flex flex-col gap-1 leading-none"
			>
				<span data-test-class="title">{{ item.title }} </span>
				<span class="font-bold" data-test-class="subtitle">
					{{ item.subtitle || '-' }}
				</span>
			</div>
		</div>
	}
</div>
