@for (blok of contentInputs; track $index) {
	<div>
		@if (components) {
			<ndc-dynamic
				[ndcDynamicComponent]="components[blok['component']]"
				[ndcDynamicInputs]="blok"
			/>
		}
	</div>
}
<section class="section-spacing bg-accent-25">
	<div
		class="section-gap mx-auto max-w-screen-2xl children:w-full children:md:mx-auto children:lg:w-10/12"
	>
		@if (roomsData$ | async; as roomsData) {
			@for (roomsWithCategory of roomsData; track $index) {
				@if (roomsWithCategory.rooms.length > 0) {
					<h3 class="running-head" data-test-class="running-head">
						{{ roomsWithCategory.category }}
					</h3>
					@for (room of roomsWithCategory.rooms; track $index) {
						<vp-persuade-card
							[cardUrl]="currentUrl + '/' + room.slug"
							[content]="room.summary"
							[ctaUrl]="
								room.isBookable
									? this.bookingtool +
										this.hotelSlug +
										'?roomGUID=' +
										room.GUID
									: null
							"
							[currency]="room.price.currency"
							[facilities]="room.facilities"
							[images]="room.images"
							[price]="room.isBookable ? room.price.amount : null"
							[roomSurface]="room.surfaceArea"
							[showReadMore]="true"
							[title]="room.title"
							imageVariant="mini-carousel"
						/>
					}
				}
			}
		}
	</div>
</section>
