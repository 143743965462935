import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';

import { TagColor } from './contracts/tag.types';

export const colorMap: Record<TagColor, string> = {
	accent: 'tag-accent',
	black: 'tag-black',
	blue: 'tag-blue',
	'dark-accent': 'tag-dark-accent',
	'dark-blue': 'tag-dark-blue',
	green: 'tag-green',
	'light-green': 'tag-light-green',
	'light-lime': 'tag-light-lime',
	magenta: 'tag-magenta',
	orange: 'tag-orange',
	pink: 'tag-pink',
	yellow: 'tag-yellow',
};

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	imports: [NgClass],
	selector: 'vp-tag',
	standalone: true,
	templateUrl: './tag.html',
})
export class TagComponent implements OnInit {
	@Input() color: TagColor = 'dark-blue';
	@Input() colorClass = '';
	@Input() isEmphasized = true;

	ngOnInit(): void {
		this.colorClass = colorMap[this.color];
	}
}
