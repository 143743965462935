import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	CUSTOM_ELEMENTS_SCHEMA,
	effect,
	Input,
	input,
} from '@angular/core';

import { ISbRichtext, renderRichText } from '@storyblok/js';

import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { ColumnComponentAlignments } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'sb-richtext',
	templateUrl: './rich-text.html',
	standalone: true,
	imports: [ReadMoreComponent, NgClass, StoryblokDirective],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RichTextComponent extends StoryblokRootDirective {
	@Input() listStyle: 'list-checkmarks' | 'list-bullets' = 'list-bullets';
	@Input() isReadMore = false;
	@Input() richTextAlignment:
		| 'text-left'
		| 'text-center'
		| 'text-right'
		| undefined = undefined;

	content = input<ISbRichtext | string>('');
	alignment = input<ColumnComponentAlignments>('left');
	renderedRichText = computed<string | ISbRichtext>(() => {
		const content = this.content();
		if (typeof content !== 'string') {
			return renderRichText(content);
		} else {
			return content;
		}
	});

	textAlignment: 'text-left' | 'text-center' | 'text-right' = 'text-left';

	constructor() {
		super();

		effect(() => {
			switch (this.alignment()) {
				case 'left':
					this.textAlignment = 'text-left';
					break;
				case 'center':
					this.textAlignment = 'text-center';
					break;
			}
		});
	}
}
