import { CommonModule } from '@angular/common';
import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	OnInit,
} from '@angular/core';

import { LinkListComponent as UILinkList } from '@valk-nx/components/ui-link-list/src/lib/link-list.component';
import { LinkListItem as UILinkListItem } from '@valk-nx/components/ui-link-list/src/lib/link-list.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { LinkListItem } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-link-list',
	templateUrl: './link-list.html',
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [UILinkList, StoryblokDirective, CommonModule],
})
export class LinkListComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input() blocks: LinkListItem[] = [];
	@Input() showDivider = true;
	@Input() showIcons = true;
	@Input() useChevron = true;

	listItems: UILinkListItem[] = [];

	ngOnInit() {
		this.listItems = this.blocks.map((item) => ({
			text: item.text,
			icon: item.icon,
			url: item.url.cached_url,
			isExternal:
				item.url.linktype !== 'story' || item.url.target === '_blank',
		}));
	}
}
