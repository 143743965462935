/* istanbul ignore file */
import { CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { DynamicModule } from 'ng-dynamic-component';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { MarkdownModule } from 'ngx-markdown';

import { AccordionComponent } from '@valk-nx/components/ui-accordion/src/lib/accordion';
import { AlertComponent } from '@valk-nx/components/ui-alert/src/lib/alert.component';
import { BannerComponent } from '@valk-nx/components/ui-banner/src/lib/banner';
import { CarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import { FooterComponent } from '@valk-nx/components/ui-footer/src/lib/footer';
import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { ItemsSliderComponent } from '@valk-nx/components/ui-items-slider/src/lib/items-slider';
import { LinkComponent } from '@valk-nx/components/ui-link/src/lib/link';
import { LinkListComponent } from '@valk-nx/components/ui-link-list/src/lib/link-list.component';
import { MiniCarouselComponent } from '@valk-nx/components/ui-mini-carousel/src/lib/mini-carousel';
import { NewsletterComponent } from '@valk-nx/components/ui-newsletter/src/lib/newsletter';
import { RecaptchaNotificationComponent } from '@valk-nx/components/ui-recaptcha-notification/src/lib/recaptcha-notification';
import { SheetModule } from '@valk-nx/components/ui-sheet/src/lib/sheet.module';
import { StatusHeaderModule } from '@valk-nx/components/ui-status-header/src/lib/status-header.module';
import { StorytellingComponent } from '@valk-nx/components/ui-storytelling/src/lib/storytelling';
import { UspBarComponent } from '@valk-nx/components/ui-usp-bar/src/lib/usp-bar.component';
import { BookingWidgetComponent } from '@valk-nx/compositions/ui-booking-widget/src/lib/booking-widget.component';
import { CookieManagerComponent } from '@valk-nx/compositions/ui-cookie-manager/src/lib/cookie-manager.component';
import { CtaCardModule } from '@valk-nx/compositions/ui-cta-card/src/lib/cta-card.module';
import { DealCardModule } from '@valk-nx/compositions/ui-deal-card/src/lib/deal-card.component.module';
import { HotelByDealCardModule } from '@valk-nx/compositions/ui-hotel-by-deal-card/src/lib/hotel-by-deal-card.component.module';
import { HotelByDealCardListModule } from '@valk-nx/compositions/ui-hotel-by-deal-card-list/src/lib/hotel-by-deal-card-list.module';
import { MasonryComponent } from '@valk-nx/compositions/ui-masonry/src/lib/masonry.component';
import { ReceiptSummaryComponent } from '@valk-nx/compositions/ui-receipt-summary/src/lib/receipt-summary.component';
import { PricePipe } from '@valk-nx/core/lib/pipes/price/price.pipe';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { PostalCodeFacade } from '@valk-nx/postal-code-store/lib/postal-code.facade';
import { AvailabilityDealService } from '@valk-nx/services/availability-deal/src/lib/availability-deal.service';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';
import { AccordionComponent as SbAccordionComponent } from '@valk-nx/storyblok/components/accordion/src/lib/accordion';
import { ActivateGiftcardComponent } from '@valk-nx/storyblok/components/activate-giftcard/src/lib/activate-giftcard';
import { AnchorComponent } from '@valk-nx/storyblok/components/anchor/src/lib/anchor';
import { BalanceGiftcardComponent } from '@valk-nx/storyblok/components/balance-giftcard/src/lib/balance-giftcard';
import { BookingWidgetComponent as SbBookingWidget } from '@valk-nx/storyblok/components/booking-widget/src/lib/booking-widget';
import { CardComponent } from '@valk-nx/storyblok/components/card/src/lib/card';
import { CarouselComponent as SbCarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { CarouselWithAvailabilityWidgetComponent } from '@valk-nx/storyblok/components/carousel-with-availability-widget/src/lib/carousel-with-availability-widget';
import { CarouselWithBookingWidgetComponent } from '@valk-nx/storyblok/components/carousel-with-booking-widget/src/lib/carousel-with-booking-widget';
import { ColumnsComponent } from '@valk-nx/storyblok/components/columns/src/lib/columns';
import { CtaCardComponent } from '@valk-nx/storyblok/components/cta-card/src/lib/cta-card';
import { DealCardComponent } from '@valk-nx/storyblok/components/deal-card/src/lib/deal-card';
import { FooterComponent as SbFooterComponent } from '@valk-nx/storyblok/components/footer/src/lib/footer';
import { FormComponent } from '@valk-nx/storyblok/components/form/src/lib/form';
import { HeaderComponent } from '@valk-nx/storyblok/components/header/src/lib/header';
import { HeadingGroupComponent } from '@valk-nx/storyblok/components/heading-group/src/lib/heading-group';
import { HeadingGroupSectionComponent } from '@valk-nx/storyblok/components/heading-group-section/src/lib/heading-group-section';
import { HotelByDealCardComponent } from '@valk-nx/storyblok/components/hotel-by-deal-card/src/lib/hotel-by-deal-card';
import { HotelByDealListComponent } from '@valk-nx/storyblok/components/hotel-by-deal-list/src/lib/hotel-by-deal-list';
import { ImageComponent as SbImageComponent } from '@valk-nx/storyblok/components/image/src/lib/image';
import { StoryblokIntroductionSectionComponent } from '@valk-nx/storyblok/components/introduction-section/src/lib/introduction-section';
import { LinkListComponent as SbLinkListComponent } from '@valk-nx/storyblok/components/link-list/src/lib/link-list';
import { MarkdownComponent } from '@valk-nx/storyblok/components/markdown/src/lib/markdown';
import { StoryblokMasonryComponent } from '@valk-nx/storyblok/components/masonry-section/src/lib/masonry-section';
import { MiniCarouselComponent as SbMiniCarouselComponent } from '@valk-nx/storyblok/components/mini-carousel/src/lib/mini-carousel';
import { NewsletterComponent as SbNewsletterComponent } from '@valk-nx/storyblok/components/newsletter/src/lib/newsletter';
import { OrderGiftcardModule } from '@valk-nx/storyblok/components/order-giftcard/src/lib/order-giftcard.module';
import { OrderGiftcardBusinessComponent } from '@valk-nx/storyblok/components/order-giftcard-business/src/lib/order-giftcard-business';
import { PageComponent } from '@valk-nx/storyblok/components/page/src/lib/page.component';
import { ReferenceComponent } from '@valk-nx/storyblok/components/reference/src/lib/reference.component';
import { RichTextComponent } from '@valk-nx/storyblok/components/richtext/src/lib/rich-text';
import { SbRootComponent } from '@valk-nx/storyblok/components/root/src/lib/root.component';
import { RunningHeadComponent } from '@valk-nx/storyblok/components/running-head/src/lib/running-head';
import { SectionComponent } from '@valk-nx/storyblok/components/section/src/lib/section';
import { SheetComponent } from '@valk-nx/storyblok/components/sheet/src/lib/sheet';
import { StoryblokStoryTellingComponent } from '@valk-nx/storyblok/components/storytelling-section/src/lib/storytelling-section';
import { UspWrapperComponent } from '@valk-nx/storyblok/components/usp-wrapper/src/lib/usp-wrapper';
import { SbWrapperComponent } from '@valk-nx/storyblok/components/wrapper/src/lib/wrapper.component';
import { MeetingRoomDetailComponent } from '@valk-nx/storyblok/pages/meeting-room-detail/src/lib/meeting-room-detail.component';
import { MeetingRoomOverviewComponent } from '@valk-nx/storyblok/pages/meeting-room-overview/src/lib/meeting-room-overview.component';
import { PackageDetailComponent } from '@valk-nx/storyblok/pages/package-detail/src/lib/package-detail.component';
import { PackageOverviewComponent } from '@valk-nx/storyblok/pages/package-overview/src/lib/package-overview.component';
import { RoomDetailComponent } from '@valk-nx/storyblok/pages/room-detail/src/lib/room-detail.component';
import { RoomOverviewComponent } from '@valk-nx/storyblok/pages/room-overview/src/lib/room-overview.component';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { MenuItemsModule } from '@valk-nx/storyblok-pipes/src/lib/pipes/menu-items/menu-items.module';
import { GiftcardService } from '@valk-nx/storyblok-services/src/lib/services/giftcard.service';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { StoryblokParamsService } from '@valk-nx/storyblok-services/src/lib/services/storyblok-params.service';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';

@NgModule({
	declarations: [],
	imports: [
		AccordionComponent,
		ActivateGiftcardComponent,
		AlertComponent,
		AnchorComponent,
		BalanceGiftcardComponent,
		BannerComponent,
		BookingWidgetComponent,
		BrowserAnimationsModule,
		BrowserModule,
		CardComponent,
		CarouselComponent,
		CarouselWithAvailabilityWidgetComponent,
		CarouselWithBookingWidgetComponent,
		ColumnsComponent,
		CookieManagerComponent,
		CtaCardComponent,
		CtaCardModule,
		DealCardComponent,
		DealCardModule,
		DynamicModule,
		FooterComponent,
		FormComponent,
		HeaderComponent,
		HeadingGroupComponent,
		HeadingGroupSectionComponent,
		HotelByDealCardComponent,
		HotelByDealCardListModule,
		HotelByDealCardModule,
		HotelByDealListComponent,
		ImageComponent,
		ItemsSliderComponent,
		LinkComponent,
		LinkListComponent,
		MasonryComponent,
		MarkdownComponent,
		MarkdownModule.forRoot({}),
		MeetingRoomDetailComponent,
		MeetingRoomOverviewComponent,
		MenuItemsModule,
		MiniCarouselComponent,
		NewsletterComponent,
		OrderGiftcardBusinessComponent,
		OrderGiftcardModule,
		PackageDetailComponent,
		PackageOverviewComponent,
		PageComponent,
		RecaptchaNotificationComponent,
		RecaptchaV3Module,
		ReceiptSummaryComponent,
		ReferenceComponent,
		RichTextComponent,
		RoomDetailComponent,
		RoomOverviewComponent,
		RouterModule,
		RunningHeadComponent,
		SbAccordionComponent,
		SbBookingWidget,
		SbCarouselComponent,
		SbFooterComponent,
		SbImageComponent,
		SbLinkListComponent,
		SbMiniCarouselComponent,
		SbNewsletterComponent,
		SbRootComponent,
		SbWrapperComponent,
		SectionComponent,
		SharedFormlyModule,
		SheetComponent,
		SheetModule,
		StatusHeaderModule,
		StoryblokDirective,
		StoryblokIntroductionSectionComponent,
		StoryblokMasonryComponent,
		StoryblokStoryTellingComponent,
		StorytellingComponent,
		TranslateModule,
		UspBarComponent,
		UspWrapperComponent,
	],
	exports: [BrowserModule, DynamicModule, SbWrapperComponent],
	providers: [
		AvailabilityDealService,
		CurrencyPipe,
		GiftcardService,
		MetadataService,
		PostalCodeFacade,
		PricePipe,
		StoryblokParamsService,
		StoryblokService,
		TagManagerFacade,
		ViewPortService,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StoryblokSharedModule {}
